
/* My Page */
.view-profile {
	main h3 {
		border-bottom: 2px solid #D9D9DB;
		color: #2D2E30;
		font: normal 400 24px/1.2 'Noto Serif',Arial,serif;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}
	.mypage__promobox--module { margin-left: 20px }
	.nowrap {
		white-space: nowrap;
	}
	.tab-content { padding: 15px 0 }
	.tab-content .col-md-12 { padding: 0 }
	#login-form {
		label { min-width: 80px }
		.control-group { margin: 5px 0 }
        input[type=text], input[type=password]{
            margin-bottom: 15px;
            width: 280px;
        }
        @media (max-width: 768px) {
            input[type=text], input[type=password]{
                width: 50%;
            }
        }
	}
	#profile {
		.account__balance { display: inline; }
		.account__number { color: inherit; }
	}
	.account {
		background: #373b48;
		border-radius: 5px;
		color: #a6a6a6;
		float: right;
		padding: 15px;
		width: 300px;
		.row {
			padding: 0 0 9px;
    		margin-bottom: 9px;
			border-bottom: 1px solid #a6a6a6;
			&:last-child{
				padding: 0;
				margin: 0;
				border: none;
			}
		}
		&__label {
			font-size: 18px;
			margin-bottom: 8px;
		}
		&__number {
			font-size: 18px;
			font-weight: bold;
			color: #fff;
		}
		&__balance {
			font-weight: bold;
			display: block;
			&--negative { color: #EB2632; }
			&--positive { color: #4caf50; }
		}
	}
    .additionalInfo {
        float: right;
        padding: 15px;
        width: 300px;
    }
	.personalAccountNumber span { word-wrap: break-word }
	.usermessage {width: 78%}
	.users-profile-custom-snprofile { padding-bottom: 30px }
	.users-profile-custom-snprofile dd {
		margin-left: 260px;
		line-height: 2em;
	}
	.users-profile-custom-snprofile dt {
		line-height: 2em;
		white-space:normal;
		width: 240px;
	}
	#shipments .fa-3x { color: #ccc; font-size: 2em; margin-left: 47%; margin-top: 40px;}
	.billings {
		li i { color: #24B1A6; font-size: 18px; margin-right: 7px; }
		li a {
			color: #111;
			border: 1px solid #f0f0f0;
			font-size: 15px;
			padding: 15px;

		}
		li.active a {
			background: #fafafa;
			border-color: #24B1A6;
			border-bottom: 1px solid #fff;
		}
		.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			-ms-border-radius: 0;
			-o-border-radius: 0;
			border-radius: 0;
		}
		.nav>li>a{
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			-ms-border-radius: 0;
			-o-border-radius: 0;
			border-radius: 0;
		}
		.nav>li>a:hover {
			background: #F1F9FB;
		}
		.table {
			*border-collapse: collapse; /* IE7 and lower */
   			border-spacing: 0;
			color: #333;
			font: normal 400 13px/1 'Open Sans',Arial,serif;
			margin: 15px 0;

			.table { margin: 0 }
			th {
				background: #737475;
				font-weight: bold;
			}

			th:first-child { border-radius: 4px 0 0 0; min-width: 90px;}
			th:last-child { border-radius: 0 4px 0 0; }
			.fa-calendar { color: #852b2b }
			.table tr,
			.table tr> td {
				background: none !important;
				border: 0!important;
			}
			.table tr { border-bottom: 1px dotted #ccc!important; margin-bottom: 10px}
			.table tr:first-child > td { padding-top: 0}
			.table tr:last-child { border: 0!important}
			.table tr:only-child { border: 0 !important }
			.subscription { font-size: 85%; font-weight: normal  }
			.col-md-11 { font-weight: bold }
			.productprice { font-weight: bold}
			.btn {
                padding: 4px 8px;
                font-size: 10px;
                &:active,
                &:focus {
                    color: #fff;
                }
            }
            .btn-pay {
                width: 100%;
            }
		}
		.table tr:hover,
		.table tr:hover .table td { background: #d9feff!important }
		.table tr:nth-child(even) .table { background: #F6F6F6; }
		.table-bordered, .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th { border: 0}
		.table-bordered > tbody > tr > td:last-child { font-size: 90%; }
		.tab-pane table tr:nth-child(even) { background: #F6F6F6 }
		.tab-pane table tr { border-bottom: 1px solid #ccc }
	}
	.shipment-tracking span {
		background: lighten(#337ab7, 7%);
		color: #fff;
		display: block;
		margin-top: 5px;

		&:hover { background: #E63425; color: #fff }
	}
	#member-profile input[type=email],
	#member-profile input[type=password],
	#member-profile input[type=tel],
	#member-profile input[type=text],
	#password-reset input[type=text],
	#member-profile input[type=url],
	.form--controls input[type=email],
	.form--controls input[type=password],
	.form--controls input[type=tel],
	.form--controls input[type=text],
	.form--controls input[type=url],
	#member-profile select,
	#member-profile textarea {
		line-height: 39px;
	    height: 39px!important;
	    font-size: 14px;
		max-width: 98%;
	    width: 100%;
	    display: block;
	}
	#password-reset .control-label,
	#member-profile .control-label {
		clear: left;
		float: left;
		min-width: 130px;
	}
	.form--controls .control-label {
		width: 30%;
    	line-height: 37px;
    	clear: left;
		float: left;
	}
	#password-reset {
		background: #373b48;
		color: #fff;
		border-radius: 3px;
		display: none;
		margin-bottom: 20px;
		overflow: hidden;
		padding: 15px;
		width: 85%;

		.grey {
			background: #000;
			color: #fff;
			border-color: #333;
			&:hover {
				background: $snMainColor;
				border-color: $snMainColor;
			}
		}
		.controls--buttons { padding-bottom: 0 }
	}
	#member-profile .controls,
	.form--controls .controls {
		clear: right;
		float: left;
		padding-bottom: 7px;
	}
	.form--controls .controls--buttons,
	.controls--buttons {
		clear: both;
		display: block;
		padding: 5px 0 25px;
		width: 100%;
		button { margin-right: 5px }
	}
}

body.fi-FI, body.nl-BE, body.fr-FR {
	&.view-profile .users-profile-custom-snprofile {
		dt { width: 400px; }
		dd { margin: 15px 0 15px 420px; }
		dt, dd { line-height: 1.75em; }
	}
}

.resetbtn--psswd {
	margin-left: 10px;
}
.usermessage {
	border: 1px solid #ddd;
	padding: 15px;
	margin-bottom: 15px;
	border-radius: 5px;
	position: relative;
	opacity: 0.65;

	&:hover { opacity: 1; cursor: pointer }
	p { margin: 0 0 0 60px}
}
.usermessage--warning {
	background: #fae88e;
	border-color: #e4ba6f;
}
.usermessage--warning:before {
	color: #eb5e60;
	content: "\f071";
	display: inline-block;
    font: normal normal normal 40px/1 FontAwesome;
    font-size: 40px;
 	position: absolute;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.notify__badge {
	display: block;
    position: absolute;
    top: -6px;
    right: 6px;
    line-height: 18px;
    height: 18px;
    padding: 0 5px;
    font: normal 400 12px/16px 'Open Sans',Arial,serif;
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.25);
    border: 1px solid;
    border-radius: 18px;
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.08);
}
.notify__badge.red {
	background: #FF0000;
    border-color: #FF0000;;
}
.view-reset {
	fieldset { margin-bottom: 10px }
}
.reset-confirm {
	input[type="text"],
	input[type="email"]{
		min-width: 300px
	}
}
.view-login .main {
	input[type=email], input[type=password], input[type=text], input[type=url], input[type=tel], select, textarea {
		line-height: 39px;
		height: 39px!important;
		font-size: 14px;
		width: 100%;
		max-width: 300px!important;
		display: block;
	}
	.controls {
		margin-bottom: 5px;
		.grey,
		.btn-primary {
			padding: 12px 35px;
			margin-top: 10px;
		}
	}
	#form-login-remember {
		margin: 10px 0;
		#modlgn-remember{
			width:13px !important;
		}
	}
	ul.dl-horizontal {
		list-style: none;
		padding: 20px 40px 20px 0;

		span { width: 120px; font-weight: bold; margin-right: 15px; display: inline-block}
		li { padding: 7px 0; border-top: 1px solid #DFDFDF}
		li:last-child { border-bottom: 1px solid #DFDFDF }
	}
}
.account__actions {
	border-top: 1px solid #ddd;
	padding-top: 10px;
	margin-top: 20px;
	padding-left: 0;
	list-style: none;
}
.account__checkbox__div {
	width: 100%;
	margin-bottom: 15px;
	padding: 5px;
	min-width: 60px;
}
.account__checkbox__div--line {
	width: 100%;
	margin-bottom: 15px;
	padding-left: 12px;
	min-width: 60px;
}
.div-header-last {
	text-align: center;
	margin-left: -15px;
    white-space: nowrap;
}

.magic-checkbox--gray + label:hover:before {
	border-color: #c0c0c0 !important;
}

.btn-group-yesno { overflow: hidden; width: 97%!important; padding: 0 }
.switch-title { margin-bottom: 6px }
.btn-group-yesno input { z-index: 10; opacity: 0; position: absolute!important }
.btn-group-yesno label { float: left }
.btn-group-yesno label:not(.has-error) {
    display: inline-block;
    z-index: 20;
    width: 50%;
    background-color: #f0f0f0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
     padding: 6px 14px;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1);
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition:    all 0.1s ease-in-out;
    -ms-transition:     all 0.1s ease-in-out;
    -o-transition:      all 0.1s ease-in-out;
    transition:         all 0.1s ease-in-out;
}
.btn-group-yesno label.has-error { width: 100%; line-height: 1.2em; padding: 5px; }
.btn-group-yesno label:hover { cursor: pointer }
.btn-group-yesno input:checked + label {
	background-color: #92D057;
	-webkit-box-shadow: none;
	box-shadow: none;
}

#show_more_invoices {
	width: 100%;
	opacity: 0.4;
	&:hover { opacity: 0.9;	}
}
#payselected { margin-top: 15px; }
#after_table { width: 100%; }
.danger {
	cursor: pointer;
	color: red;
}
h5.modal-title {
    display: inline-block;
    font-size: 20px;
    margin: 0px;
    padding: 5px;
}
.no-data {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5em;
	background: #f6f6f6;
	color: grey;
	margin-bottom: 10px;
}
div.max-orders {
    text-align: center;
}
div.alert-top {
    width: 100%;
    float: left;
}
.sap-offline-message {
    color: #fff;
    background-color: #2196F3;
    padding: 15px 15px 5px 15px;
    border-radius: 3px;
    margin-bottom:20px;
}

.button-container {
    float: right;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.small-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.text-muted {
    text-align: center; /* Center the text inside the small-container */
}
