$mediumDesktop: 1140px;
$tabletWidth: 1024px;
$mediumDevice: 992px;
$smallDevice: 768px;
$phoneDevice: 560px;
$extraSmallDevice: 400px;

$sntext: #111;
$snNewsletter: #333;
$sngrey: #f2f2f2;
$snlightgray: #f2f2f2;
$snMainColor: #852b2b;
$snMenuHeight: 45px;
$snborder: #dedede;
$snBlue: #373b48;
$snYellow: #e0c78b;
$snRed: #e3000a;
$claret: #4f0002;
$navyBlue: #364d7f;
$blissfulJoanna: #3a567a;
$infoBarHeight: 35px;
$baseFont: "Arial";
$hBoxBackground: #f7f7f7;
$hBoxBorder: #dcdcdc;
$baseWidth: 1140px;
$mobileMenuWidth: 340px;
$snGreen: #698826;
$button-main-green: #38761d;

@mixin transition($time: 0.3s) {
    -webkit-transition: all $time ease-in-out;
    -moz-transition: all $time ease-in-out;
    -o-transition: all $time ease-in-out;
    transition: all $time ease-in-out;
}
