@-webkit-keyframes fadeIn {
    0% {
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
    }
    100% {
        opacity: 1;
    }
}
// no way to merge this placeholder styles, browsers bug (!)
::-webkit-input-placeholder {
    font-style: italic;
}
::-moz-placeholder {
    font-style: italic;
}
:-ms-input-placeholder {
    font-style: italic;
}
input:-moz-placeholder {
    font-style: italic;
}
textarea:focus,
input:focus {
    outline: 0;
}
*:focus {
    outline: 0;
}
a {
    cursor: pointer;
    color: $snMainColor;
    outline: 0;
}
ol,
ul {
    padding-left: 20px;
}
input::-moz-focus-inner {
    border: 0;
}
a:hover,
a:focus,
a:active {
    color: $sntext;
    outline: 0;
    text-decoration: none;
}
html {
    font-size: 16px;
    -webkit-font-smoothing: subpixel-antialiased;
}
img {
    max-width: 100%;
}
iframe {
    border: none;
    width: 1px;
    min-width: 100%;
    *width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch !important;
    overflow-y: scroll !important;
}
body {
    font-family: "Open Sans", Arial, serif;
    color: #111;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
input[type="text"],
input[type="email"] {
    -webkit-appearance: none;
    border-radius: 0;
}
input[type="checkbox"],
.checkbox,
input[type="radio"],
.radio {
    background: transparent !important;
    border: none !important;
    height: auto !important;
    margin-top: 7px !important;
}
select[size] {
    font-size: 12px;
    height: auto;
}
select[size="1"] {
    height: 39px;
    line-height: 39px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="url"],
input[type="email"] {
    background: #fff;
    border: 1px solid #cdcdcd;
    color: #111;
    font-size: 13px;
    line-height: 1.2;
    height: 33px !important;
    padding: 6px 15px !important;
    max-width: 100% !important;
}
label + select,
input[type="checkbox"] + label {
    margin-left: 8px;
}
label {
    color: $sntext;
    font-weight: 400;
}
.view-purchaselist {
    h1,
    h3 {
        font: normal 400 40px/1.3 "Noto Serif", Arial, serif;
        margin: 15px 0 20px;
    }
    h3 {
        font-size: 24px;
    }
}
.com_content,
.contentpane,
.tab-pane {
    font-size: 16px;
    line-height: 1.4em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .testimonial__text {
        font: normal 400 40px/1.3 "Noto Serif", Arial, serif;
        margin: 15px 0 20px;
        word-break: break-word;
    }
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 30px;
    }
    h4 {
        font-size: 28px;
    }
    h5 {
        font-size: 26px;
    }
    h6,
    .testimonial__signature {
        font-size: 24px;
    }
    p {
        margin-bottom: 15px;
    }
    .article-info {
        dt,
        dd {
            display: inline-block;
            margin-right: 15px;
        }
    }
    .article__intro {
        font: italic 400 18px/1.5 "Noto Serif", Arial, serif;
        img {
            height: auto;
            margin-top: 5px;
        }
    }

    figure {
        background: #e3e3e3;
        border: 1px solid #ccc;
        display: inline-block;
        padding: 10px;
        img {
            border: 1px solid #ccc;
        }
    }
    figure.pull-left {
        margin: 15px 15px 15px 0;
    }
    figure.pull-right {
        margin: 15px 0 15px 15px;
    }
    figure.pull-center {
        margin: 15px auto;
        display: table;
    }
    figcaption {
        color: #888;
        font-size: 12px;
        margin-top: 5px;
        text-align: center;
        text-transform: uppercase;
    }
    table {
        border: 0 !important;
        border-color: $snMainColor;
        width: 100%;
        thead {
            color: #fff;
            font-weight: normal;
            th {
                background: $snMainColor;
                font-weight: normal;
                padding: 10px 15px;
            }
        }
        td {
            padding: 10px 15px;
            border-color: $snMainColor;
        }
        tr:nth-child(even) {
            background: #f1eeee;
        }
        caption {
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
        }
    }
    blockquote {
        background: #f1eeee;
        border-left-color: #c6a0a0;
        font: italic 400 17px/1.5 "Noto Serif", Arial, serif;
        margin: 25px 0;
        padding: 30px 35px;
    }
    .item-page {
        ul {
            list-style: none;
            padding: 0;
            margin: 20px 0;
        }
        li {
            padding-left: 1em;
            text-indent: -0.7em;
            margin-bottom: 15px;
        }
        li:before {
            font: normal normal normal 10px/1.5 FontAwesome;
            content: "\f111";
            color: $snMainColor;
            margin-right: 10px;
            position: relative;
            top: -3px;
        }
        ol > li:before {
            display: none;
        }
        ul.pager,
        ul.pagenav {
            li:before {
                display: none;
            }
            li > a {
                border-radius: 0;
                padding: 5px 14px !important;
                background: #fff !important;
                border: 1px solid #ddd !important;
                text-indent: 0;
                &:hover {
                    background: #852b2b !important;
                }
            }
        }
    }
}

.view-categories {
    h1 {
        margin-bottom: 40px;
    }
    .col-md-6 {
        padding-left: 0;
        padding-right: 30px;
    }
    .categories-list {
        padding-left: 20px;
        .row {
            margin-bottom: 30px;
        }
    }
    .category {
        ul li a {
            color: $sntext;
            font-size: 14px;
            line-height: 18px;
            &:hover {
                color: $snMainColor;
                margin-left: 5px;
                @include transition();
            }
        }
    }
}

#mailto-window {
    background: #f2f2f2;
    padding: 35px;

    .mailto-close {
        font-size: 14px;
        position: absolute;
        right: 10px;
        top: 5px;

        a {
            color: $sntext;
            &:hover {
                color: $snMainColor;
            }
        }
    }
    .mailto-close span::after {
        border: 1px solid $sntext;
        content: "\f00d";
        font: normal normal normal 14px/1 FontAwesome;
        display: "inline-block";
        margin-left: 5px;
        padding: 5px 6px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
    }

    h2 {
        font-size: 26px;
        margin: 0 0 30px;
    }
    .formelm:last-child {
        padding-bottom: 30px;
    }
    .formelm {
        overflow: hidden;
        padding-bottom: 10px;
        label {
            font-size: 13px;
            line-height: 20px;
        }
        input {
            float: right;
        }
    }
    p {
        margin: 15px 0;
    }
}

#system-message-container {
    color: #fff;
    font-family: "Open Sans", Arial, serif;
    border: 0;
    display: block;
    width: 100%;
    h4 {
        font: normal normal 600 20px/23px "Open Sans", Arial, serif;
        margin: 0 0 15px;
    }
    p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .alert {
        margin: 15px 0;
        background: #4e83ba;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
    }
    .alert-info {
        background: #4e83ba;
    }
    .alert-danger {
        background: #e47f3c;
    }
    .alert-error {
        background: #e47f3c;
    }
    .alert-warning {
        background: #4e83ba;
        color: #fff;
        border: 0;
    }
    .alert-success {
        background: #98ba4e;
        color: #fff;
    }
    .alert.alert-message {
        background: #98ba4e;
    }
}
a.modal {
    display: inherit;
    position: inherit;
    z-index: 0;
}
.nopadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.nopadding--left {
    padding-left: 0 !important;
}
.nopadding--right {
    padding-right: 0 !important;
}
.xs-nopadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.view-categories.com_content h3 {
    margin: 0 0 10px 0;
    font: normal 600 14px/1.4 "Open Sans", Arial, serif;
}
.view-categories .col-md-6 {
    width: 50%;
}

/* Article */
.article__tags {
    clear: both;
    display: block;
    padding: 15px 0;
    width: 100%;

    > span {
        font: normal 600 14px/1.4 "Open Sans", Arial, serif;
        color: #111;
        display: block;
        width: 100%;
        margin-left: 7px;
        padding: 5px 0;
        border-bottom: 1px solid #ddd;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        li {
            display: inline-block;
        }

        li:before {
            display: none;
        }
    }
    .label {
        background: transparent;
        color: $snMainColor;
        font-weight: normal;

        &:hover {
            background: $snMainColor;
            color: #fff;
        }
    }
}

/* Tags view */
.view-tag {
    ul.category {
        list-style: none;
        padding-left: 0;

        li {
            display: inline-block;
            padding-right: 10px;
            h3 {
                font: normal 600 14px/1.4 "Open Sans", Arial, serif;
            }
        }
    }
}
