/** paybylink **/
.main_container {
    text-align: center;
    max-width: 500px;
    margin: auto;
    padding: 20px 3vw;
}
.ssn-form {
    display: inline-block;
    max-width: 100%;
    width: 500px;
}
input.ssn {
    width: 500px;
}
.paybylink-button {
    margin-top: 5px;
    width: 100%;
}
.paybylink-button:after {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
	content: "\f054";
	padding: 0 5px 0 20px;
}
.input-as-link {
    background-color: inherit;
    color: #852b2b;
    border: 0;
}
.ssn-desc {
    display: none;
}
.ssn-desc2 {
    display: inline-block;
    margin: 40px 0;
}

.input-as-link {
    margin: 6px 0;
}
.div-form-payinvoice {
    display: flex;
    align-items: center;
    flex-direction: column;
    .form-payinvoice {
        max-width: 300px;
    }
}
