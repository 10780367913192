@import 'variables';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap';
@import 'font-awesome/font-awesome.scss';
@import 'animations.scss';
@import 'bootstrap-override.scss';
@import 'joomla.scss';
@import 'bottom-mobile-menu';
@import 'vipps';
@import 'geposit.scss';

html {
	overflow-y:scroll
}
/* columns of same height styles */
.container-xs-height {
    display:table;
    padding-left:0px;
    padding-right:0px;
}
.row-xs-height {
    display: table;
    width: 100%;
}
.col-xs-height {
    display: table-cell;
    float: none !important;
}
a.inverted {
	color: #000;
	&:hover { color: $snMainColor; }
}
.custom--trustpilot-mobile {
    display: none;
}

@media (min-width: 768px) {
    .row-sm-height {
        display: table;
        width: 100%;
    }
    .col-sm-height {
        display: table-cell;
        float: none !important;
    }
}
@media (min-width: 992px) {
    .row-md-height {
        display: table;
        width: 100%;
    }
    .col-md-height {
        display: table-cell;
        float: none !important;
    }
}
@media (max-width: 1025px) {
	.handel__logo, .site-trust{
		display:none !important;
	}
    .custom--trustpilot-mobile {
        display: block;
    }
}

@media (min-width: 1200px) {
    .row-lg-height {
        display: table;
        width: 100%;
    }
    .col-lg-height {
        display: table-cell;
        float: none !important;
    }
}

.col-top {
    vertical-align:top;
}
.col-middle {
    vertical-align:middle;
}
.col-bottom {
    vertical-align:bottom;
}
fieldset + fieldset {margin-top: 40px}
.main {
	max-width: 100%;
	padding: 15px 0 30px;

	iframe { max-width: 100% }
}
.isFrontpage .main {
	padding: 40px 0 10px;
}
body.isFrontpage {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.view-product .main,
.view-products .main { padding-top: 15px }
#content {
	padding-left: 0;
	padding-right: 0;
}
.breadcrumb {
	padding-left: 0;
	color: #333;

	a,
	.breadcrumb__item,
	.breadcrumb__separator {
		color: #333;
		display: inline-block;
		font-style: italic;
		list-style: none;
		padding: 0 3px;
	}
	a:hover{ color: $snMainColor; }
}
ol.breadcrumb {
	list-style-type: none;
}
ol.breadcrumb li {
	display: inline;
}
.sitewrapper {
	background: #fff;
	position: relative;
	-webkit-transition: -webkit-transform 0.4s ease;
    -ms-transition: -ms-transform 0.4s ease;
    transition: transform 0.4s ease;
}
.sitewrapper.sitewrapper--opened {
	-webkit-transform: translate(-$mobileMenuWidth - 10px, 0);
    -moz-transform: translate(-$mobileMenuWidth - 10px, 0);
    -ms-transform: translate(-$mobileMenuWidth - 10px, 0);
    -o-transform: translate(-$mobileMenuWidth - 10px, 0);
    transform: translate(-$mobileMenuWidth - 10px, 0);
    a { pointer-events: none; }
}
/* 1. Header area */
.minfobar {
	background: #fafafa;
	border-bottom: 1px solid #dedede;
	display: none;
	p {
		padding: 5px;
		text-align: right;
		margin: 0 12px 0 0;
		-webkit-margin-after: 0!important;
        font-size: 14px;
	}
}
.infobar {
	background: $sngrey;
	border-bottom: 1px solid $snborder;
	font-size: 14px;
	height: $infoBarHeight;
}
.main__inner,
.infobar__inner,
.header__inner,
.hbox__inner,
.menu__layout,
.menu__dropline,
.promobox__inner,
.footer__inner,
.glogos__inner,
.bottom__inner,
.copyrights__inner,
.newsletter__inner,
.mostpopular__inner {
	width: $baseWidth;
	margin: 0 auto;
	max-width: 100%;
}
.newsletter__inner { padding: 0 15px; }
.footer__inner .row { margin: 0 -15px }
.main__inner,
.mostpopular__inner { padding: 0 }
.infobar__text {
	color: $sntext;
	line-height: $infoBarHeight;
	margin-bottom: 0;

	-webkit-text-shadow: 1px 1px #fff;
	-moz-text-shadow: 1px 1px #fff;
	-ms-text-shadow: 1px 1px #fff;
	-o-text-shadow: 1px 1px #fff;
	text-shadow: 1px 1px #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.infobar__langswitch {
	min-width: 59px;
	text-align: center;
	a { color: #111 }
}
body h1.infobar__text {
	font-size: 14px;
	font-family: 'Open Sans',Arial,serif;
	line-height: $infoBarHeight;
	margin: 0!important;
}
.infobar__img {
	display: none;
	padding: 7px 10px 0 0;
}
.infobar__btn {
	background: #fff;
	border: 1px solid $snborder;
	border-top: 0;
	color: $sntext;
	height: $infoBarHeight;
	line-height: $infoBarHeight;
	margin-left: 10px;
	padding: 0 15px 0 15px;
	-webkit-transition: background-color .3s ease-in-out, color .3s ease-in-out;
    -moz-transition: background-color .3s ease-in-out, color .3s ease-in-out;
    -o-transition: background-color .3s ease-in-out, color .3s ease-in-out;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;

	.fa {
		color: $snMainColor;
		font-size: 16px;
		margin-right: 5px;
	}

	&:hover {
		background: $snMainColor;
		color: #fff;
		.fa { color: #fff }
	}
}
.header__row {
	font-size: 14px;
	line-height: 1.42857;
}
.site-trust{
	float: right;
	margin: 10px 10px 0 0;
	width:55px;
}
/* 1.1 Site Search */
.site-search {
	form { border: 1px solid $snborder }

    .search-query {
		border: 0;
		height: 45px!important;
		line-height: 45px;
		padding: 0 15px;
		width: 352px;
	}
	.fa { color: $sntext }
}

body.fr-FR, body.nl-NL, body.nl-BE {
	.site-search {
		.fa {
			color: #fff;
		}
		&:hover {
			background: #373B48;
		}
	}
}

.site-search__button {
	background: #fff;
	border: 0;
	border-left: 1px solid $snborder;
	font-size: 17px;
	height: 45px;
	margin: 0;
	padding: 10px 11px 12px 11px;
	width: 45px;
	vertical-align: top;

	@include transition();

	&:hover {
		background: $sngrey;
		color: $sntext;
	}
}
/* 1.3 Header logo */
.minicart { float: right; position: relative }
.header-menu .nav {
	clear: both;
	float: right
}
.handel__logo,
.handel__wrapper {
	float: right;
	margin: 10px 0 0 0;
}
.handel__wrapper {
	max-width: 47px;
	height: auto;
}
.trygg-certificate-box { left: 50px!important }
.header-menu .nav li:last-child a {
	padding-right: 0;
}
.header-menu .nav li {
	display: inline-block;
	float: left;
	font-size: 13px;

	a {
		color: $sntext;
		padding: 0 10px;
		&:hover {
			background: transparent;
			color: $snMainColor;
		}
	}
}
.header-menu .nav li.active { text-decoration: underline}
.header__cart-mini.fadeInDown { visibility: visible }
.header__cart-mini {
	background: #fff;
	border: 2px solid #fff;
	padding: 20px 15px;
	position: absolute;
	top: 70px;
	left: -325px;
	width: 400px;
	z-index: 9999;
	// opacity: 0;
	// -ms-filter: 'progid: DXImageTransform.Microsoft.Alpha(Opacity=0)';
	// visibility: hidden;
	// filter: unquote("progid: DXImageTransform.Microsoft.Alpha(Opacity=0);");

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;

	-webkit-box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.75);
	-moz-box-shadow:    0px 0px 3px 0px rgba(50, 50, 50, 0.75);
	box-shadow:         0px 0px 3px 0px rgba(50, 50, 50, 0.75);

	h5 {
		font-size: 16px;
		margin: 0 0 10px 0;
	}
	.minicart__image { padding: 0 }
	.row {
		border-top: 1px solid #ccc;
		padding: 10px 0;
	}
	> div:last-of-type {border-bottom: 1px solid #ccc; margin-bottom: 15px }
	.minicart__price {
		float:right;
		display: block;
		font-size: 1.4rem;
        margin-right: 15px;
	}
	.row:last-child { border-bottom: 1px solid #ccc }
	.minicart__title {
		padding-right: 0;
		font-size: 90%;
	}
	.promoproduct__cart {
		bottom: 0;
		position: relative;
		right: auto;
		float: right;
	}
	small {
		display: block;
		font-style: italic;
		text-align: center;
		color: #999;
	}
    .align-right {
        text-align: right;
    }
}
.header__cart-mini:after, .header__cart-mini:before {
	top: -16px;
	left: 91%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.header__cart-mini:after {
	border-color: rgba(0, 0, 0, 0);
	border-width: 10px;
	margin-left: -10px;
}
.header__cart-mini:before {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #C6C6C6;
	border-width: 7px;
	margin-left: -20px;
}
/* 1.4 Navigation */
.navigation {
	z-index: 15;
	position: relative;
}
.navigation--prev,
.navigation--next { display:  none;}
.menu__layout {
	background: $sngrey;
	border: 1px solid #ddd;
	list-style: none;
	margin: 15px auto 0;
	position: relative;
	padding: 0;
}
// full-width menu
.navigation.full-width-menu .menu__layout {
	display: flex;
	justify-content: space-around;
	align-content: flex-start;
	flex-wrap: wrap;
	.menu_item {
		white-space: nowrap;
	}
}
.menu__item {
	display: inline-block;
	font: normal 600 15px/50px 'Open Sans', Arial, serif;
	height: $snMenuHeight;
	line-height: $snMenuHeight;
	padding: 0 25px;
	width: auto;
	text-transform: uppercase;
	-webkit-transition: background-color .3s ease-in-out;
    -moz-transition: background-color .3s ease-in-out;
    -o-transition: background-color .3s ease-in-out;
    transition: background-color .3s ease-in-out;

	a {
		color: $sntext;
		-webkit-transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
	}
	&:hover {
		background: #dfdfdf;
		border-bottom: 4px solid $snMainColor
	}
}
.menu__item.tronskiftet {
    background: #bf9b30;

    a {	color: #ffffff }
    &:hover {
        border-bottom: 4px solid #906f0a;
    }
}
.menu__item.zalgiris {
    background: #1ca256;

    a {	color: #ffffff }
    &:hover {
        background: #1ca256;
        border-bottom: 4px solid #0b3d21;
    }
}
.menu__item.bfriday {
	background: #231F20;

	a {	color: #F6E73B }
	&:hover {
		background: #000;
		border-bottom: 4px solid #F6E73B;
	}
}
.menu__item.pumpkin {
    background: #EB6123;

    a {	color: #F6E73B }
    &:hover {
        background: #000;
        border-bottom: 4px solid #F6E73B;
    }
}
.menu__item.spring {
    background: #007508;

    a {	color: #f5eced }
    &:hover {
        background: #005106;
        border-bottom: 4px solid #75a166;
    }
}
.menu__item.watersnoodramp {
    background: #1e9dd9;

    a {	color: #FFFFFF }
    &:hover {
        background: #1e9dd9;
        border-bottom: 4px solid #106389;
    }
}
.menu__item.christmas {
    background: #B3000C;

    a {	color: #D4AF37 }
    &:hover {
        background: #B3000C;
        border-bottom: 4px solid #D4AF37;
    }
}
.menu__item.koning {
    background: #FFA500;

    a {	color: #fff }
    &:hover {
        background: #d56c02;
        border-bottom: 4px solid #7b3a00;
    }
}
.menu__item.filip {
    background: #4169E1;

    a {	color: #fff }
    &:hover {
        background: #578ee1;
        border-bottom: 4px solid #043ce1;
    }
}
.menu__item.brand,
.menu__item.museum {
	background: #0a72b9;

	a {
		color: #fff;
		&:hover { color: #fff }
	}
	&:hover {
		color: #fff;
		background: darken(#0a72b9, 10%);
		border-bottom: 4px solid darken(#0a72b9, 15%);
	}

}
.menu_active.museum {
	border-bottom: 4px solid darken(#00628a, 15%);
}
.menu__item.museum {
	background: #00628a;
	&:hover {
		background: darken(#00628a, 10%);
		border-bottom: 4px solid darken(#00628a, 15%);
	}
}
.menu_active {
	border-bottom: 4px solid $snMainColor;
	margin-bottom: -1px;
	a {
		color: $snMainColor;
		&:hover { color: $sntext }
	}
}
.menu_active.bfriday {
	a {	color: #F6E73B }
	border-bottom: 4px solid #F6E73B!important;
}
.menu__dropline {
	background: $sngrey;
	border: 1px solid #ddd;
	border-top: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	opacity: 0;
	visibility: hidden;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
	filter: alpha(opacity=0);

	-webkit-transition: background-color .3s ease-in-out;
    -moz-transition: background-color .3s ease-in-out;
    -o-transition: background-color .3s ease-in-out;
    transition: background-color .3s ease-in-out;

	.menu__item {
		font-size: 12px;
		height: 35px;
		line-height: 35px;
	}
}
.menu__dropline.active {
	opacity: 1;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
	filter: alpha(opacity=100);
	visibility: visible;
	z-index: 9999;
}
.menu__row {
	margin: 0 auto;
	position: relative;
	width: $baseWidth;
}
body.menu-dropdown {
	.menu__item { padding: 0 18px }
	.menu__dropline .menu__item { display: block }
	.menu__dropline { width: auto; min-width: 190px }
}
/* 1.5 hBox area */
.hbox { padding: 0 10px; }
.hbox + .hbox { padding-top: 0!important }
.hbox__module {
	padding: 0!important;
	> div {
		padding: 5px;
	}
}
.hbox--top { padding-top: 15px }
.hbox__module img {
	max-width: 100%;
	height: auto;
}

/* 6. TopBar */
@import 'topBar';

/* 7. chat */
#intelecomchat .intelecomStarterBottom {
	bottom: 30px;
	right: 30px;
	width: 60px;
	height: 60px;
	border: 5px solid $navyBlue;
	background-color: $navyBlue;
	-webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
}
/* 8. Footer */
footer {
	background: $snBlue;
	margin: 30px 0;
	padding: 50px 0;

	.row + .row { margin-top: 40px }
}
.bottom__module {
	font-size: 90%;
	h3 {
		font: normal 600 16px/23px 'Open Sans', Arial, serif;
		margin: 0 0 10px 0;
		text-transform: uppercase;
	}
}
.footer__module {
	color: #abacb0;
	h2,
    h3 {
		color: #fff;
		font: normal 600 17px/23px 'Open Sans', Arial, serif;
		margin: 0 0 20px 0;
		text-transform: uppercase;
	}
	li a {
		color: #abacb0;
		font: normal 400 15px/43px 'Open Sans', Arial, serif;
		padding: 0!important;
		&:hover {
			background: none;
			color: #fff;
		}
	}
	a {
		color: #abacb0;
		font: normal 400 15px/35px 'Open Sans', Arial, serif;
		padding: 0;
		&:hover { color: #fff; }
	}
	p { margin-bottom: 0 }

	li { border-bottom: 1px solid #5f626d }
	li:last-child { border: 0 }

	.moduletable + .moduletable { margin-top: 50px }
	.moduletable.bordered {
		background: $snMainColor;
		color: #fff;
		padding: 17px;

		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		-ms-border-radius: 3px;
		-o-border-radius: 3px;
		border-radius: 3px;

		a {
			color: #fff;
		}
	}
}
.footer__module.col-md-9 {
	p {
		font-size: 13px;
		color: #a6a6a6;
	}
}

/* 9. Copyrights */
.copyrights__inner {
	border-top: 1px solid #ccc;
	margin-top: 20px;
	padding: 20px 0 50px 0;
}

/* 10. Newsletter */

/* 11. Group logos */
.glogos img {
	max-width: 100%;
	height: auto;
}

/* 3rd Party Modules */
/** Promobox **/
@import 'promobox.scss';

/* Frontpage */

.snpromobox {
	.menu__layout { border: 0 }
	.menu_active { margin-bottom: 0 }
	.menu__dropline { border: 0 }
}

/* Magazine */
.emagazine__pdf {
	font-size: 90%;
	display: block;
	margin-top: 10px;
}
.magazine {
	padding: 0;

	img {
		width: 100%;
		height: auto;
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease
	}
	img:hover {
		filter: contrast(130%);
	}
	a {
		color: #000;
		&:hover { color: $snMainColor }
	}
	article { padding-bottom: 40px}
}
.magazine__article--promo + .magazine__article--secondary { padding-left: 0 }
.col-md-9 .magazine__article--secondary:last-child { padding-right: 0 }
.magazine > .col-md-9 {
	padding-left : 0;
	border-right: 1px solid #b2b2b2;
}
.magazine > .col-md-3 { padding-right: 0}
.magazine__article--secondary:nth-child(even) { padding-right: 12px}
.magazine__article--secondary:nth-child(odd){ padding: 0 6px }
.magazine__article > .col-md-8 { padding-left: 0}
.magazine__article > .col-md-4 { padding-right: 0}
.magazine__article--promo,
.magazine__article--secondary {
	.magazine__title {
		font: normal 400 25px/1.3 'Noto Serif', Arial, serif;
		margin: 0 0 10px;
		text-transform: none;
	}
	.magazine__intro {
		font-size: 15px;
		margin-bottom: 30px
	}
	.magazine__readmore {
		background: $snMainColor;
		color: #fff;
		font: normal 400 15px/1.2 'Open Sans', Arial, serif;
		padding: 7px 10px;
		&:hover {
			background: $snBlue;
			color: #fff;
		}
	}
}
body.fr-FR, body.nl-NL, body.nl-BE {
	.magazine__article--promo,
	.magazine__article--secondary {
		.magazine__readmore {
			background: $button-main-green;
			&:hover {
				background: $snBlue;
				color: #fff;
			}
		}
	}
}
.magazine__article--promo { padding-left: 0 }
.magazine__article--secondary {
	.magazine__title {
		font: normal 400 18px/1.3 'Noto Serif', Arial, serif;
		margin: 0 0 20px;
	}
	img { padding-bottom: 25px }
}
.magazine__article--secondary:first-child { padding-right: 12px }
.magazine__article--secondary:last-child { padding-left: 12px }
.magazine__mostread {
	display: block;
	font: normal 400 19px/1.3 'Noto Serif', Arial, serif;
	margin-bottom: 25px;
}
.magazine__column {
	padding-left: 25px;

	.magazine__article {
		padding: 0 0 25px 0;
	}
	.magazine__title {
		font: normal 700 15px/1.3 'Noto Serif', Arial, serif;
		text-transform: none;
	}
	.magazine__readmore {
		display: inline-block;
		color: $snMainColor;
		font: normal 400 15px/1.6 'Noto Serif', Arial, serif;
		text-decoration: underline;
		&:hover { color: #000 }
	}
}
ul.nav.menu--horizontal {
	display: flex;
	li {
		flex: 1;
		text-align: center;
	}
}

.promoproduct__price {
	color: #343434;
	bottom: 10px;
	color: #585858;
	font: normal 500 19px/1.1 'Open Sans', Arial, serif;
	.crossed {
		font-size: 70%;
		font-weight: normal;
	}
}
.promoproduct__actions.buyButton {
	.promoproduct__price {
		display: flex;
	    flex-direction: column;
	    text-align: center;
		.crossed { margin-bottom: 2px }
	}
}
.promoproduct__cart,
.productview__cart,
.overlay__cart {
	background: $snMainColor;
	color: #fff;
	font: normal 400 14px/1.4 'Open Sans', Arial, serif;
	padding: 10px 15px;
	@include transition();

	&:hover { background: $snBlue; color: #fff }
}

body.fr-FR, body.nl-NL, body.nl-BE{
	.promoproduct__cart,
	.productview__cart,
	.overlay__cart {
		background: $button-main-green;
	}
}

.btn,
.button,
button {
	background: $snMainColor;
	border: 0;
	color: #fff;
	font: normal 400 14px/1.4 'Open Sans', Arial, serif;
	padding: 10px 15px;
	@include transition();
	&:hover { background: $snBlue; color: #fff }
	.fa { color: #fff; margin-right: 5px }
}
body.fr-FR, body.nl-NL, body.nl-BE{
	.btn,
	.button,
	button {
		background: $button-main-green;
		&:hover {
			background: $snBlue;
			color: #fff;
		}
	}
}
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
	background: $snBlue; color: #fff
}
.btn-inverse {
	background: $snBlue;
	&:hover { background: $snMainColor }
}
/* Most popular products module */
.view-products {
	.bottom {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.bottom__module h2 {
		font: 500 1.8em/1.4 'Noto Serif',Arial,serif;
	}
	.bottom__module { font-size: 95% }
}
.about {
    background-color: #383B48;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    padding: 25px 0 50px;
    margin-bottom: 25px;
    background-attachment: fixed;
    h3 { margin-bottom: 30px; }
    .row-fluid { overflow: hidden }
    .row-fluid + .row-fluid {  margin-top: 40px }
    .row .col-md-8 { padding-right: 0; }
    .fa,
    .icon__text {
        float: left;
        margin: 0;
        i,
        span {
            font-family: 'Noto Serif',Arial,serif;
            display: block;
        }
        span {
            font-size: 250%;
            color: #ffd224;
            margin: 10px 0;
            font-weight: bold;
        }
    }
    .fa { font-size: 60px }
}
.mostpopular__inner {
	.promoproducts {
		border: 1px solid #ccc;
		margin: 0;
		padding: 0;
	}
    .overlay__img {
        position: absolute;
        left: 0;
        top: 0;
        max-height: 65px;
        width: auto;
        z-index: 99;
    }
    .promoproducts__row:first-child {
		border-bottom: 1px solid #ccc;
	}
	h3 {
		font: normal 400 25px/1.4 'Noto Serif', Arial, serif;
		margin-bottom: 30px;
		margin-left: 10px;
	}
	.promoproduct {
		background: #fff;
		border-right: 1px solid #ccc;
		box-sizing: border-box;
		display: block;
		padding: 15px 15px 40px;
		position: relative;
		width: 25%;
		display: table-cell;
		-webkit-transform-origin: center center;
		-moz-transform-origin: center center;
		-o-transform-origin: center center;
		-ms-transform-origin: center center;
		transform-origin: center center;

		-webkit-transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
		-moz-transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
		-o-transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
		transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);

		&:hover {
			-webkit-transform: scale(1.04, 1.04);
			-moz-transform: scale(1.04, 1.04);
			-o-transform: scale(1.04, 1.04);
			-ms-transform: scale(1.04, 1.04);
			transform: scale(1.04, 1.04);
			-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
			-moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
			z-index: 10;
		}
		.promoproduct__price {
			bottom: 10px;
			margin: 0!important;
			position: absolute;
			left: 15px;
		}
		.most_popular_cart{
			background: $snMainColor;
			color: #fff;
			font: normal 400 14px/1.4 'Open Sans', Arial, serif;
			padding: 10px 15px;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
		}
		.xcolumns_addtocart{
			margin-right:10px;
		}
		.most_popular_buttons {
			position: absolute;
			bottom: 8px;
			right: -1px;
		}
		.promoproduct__cart{
			position: absolute;
			bottom: -1px;
			right: -1px;
		}
	}
	.promoproducts__row {
		clear: both;
		display: table;
		overflow: visible!important;
		table-layout: fixed;
		width: 100%;
	}
	.row { margin: 0 }
	.row:first-child {padding-bottom: 10px}
	.promoproduct__actions {
		bottom: 0;
		height: 50px;
		margin: 0 -15px;
		position: absolute;
		width: 100%;
	}
	.promoproducts__row .promoproduct:last-child { border-right: 0; }
	.promoproduct__title {
		color: #000;
		display: block;
		font: normal 500 14px/1.4 'Open Sans', Arial, serif;
		text-align: center;
		height: 59px;
		overflow: hidden;
		text-align: center;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		&:hover { color: $snMainColor }
	}
	.promoproduct__image {
		display: block;
        width: 100%;
        max-width: 400px;
        height: auto;
        aspect-ratio: 2 / 1;
        margin: 0 auto 10px;
    }
}

body.fr-FR, body.nl-NL, body.nl-BE{
	.most_popular_cart {
		background: $button-main-green!important;
	}
}

/* category layout */
.category__overlay {
  background: #fff;
  height: 100%;
  opacity: 0.75;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=75)';
  filter: alpha(opacity=75);

  position: absolute;
  top: 20px;
  width: 100%;
  @include transition();

  .fa-spin {
    position: relative;
    top: 55%;
    left: 45%;
  }
}
.product {
	opacity: 0;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
	filter: alpha(opacity=0);
	position: absolute!important;
	visibility: hidden;
}
.product-animated {
	-webkit-animation: fadeIn 1.65s ease forwards;
	animation: fadeIn 1.65s ease forwards;
	position: relative!important;
	visibility: visible
}
.product__subcategories {
	margin: 20px 30px 0 0;
	padding: 25px;
	border: 1px solid #e0c78b;
	legend {
		border: 0;
		font: normal 500 20px/1.4 'Noto Serif', Arial, serif;
	}
	a {
		display: block;
		font-size: 110%;
		color: #111;
		&:hover {
			color: #852b2b;
		}
	}
	a.active_subcategory {
		font-weight: 600;
	}
}
.products__filters-inner {
	border: 1px solid #ccc;
	margin: 20px 30px 0 0;
	padding: 45px 25px;

	label { cursor: pointer }
	legend {
		border: 0;
		font: normal 500 20px/1.4 'Noto Serif', Arial, serif;
	}
	.clear__filters {
		margin: 0 0 10px 0 !important;
		display: block;
	}
	.clear__slide {
		display: none;
	}
	.generate__filters { margin: 30px 0 10px }
	#link { display: none; }
	.link__content { height: auto!important }

}
.newsletter {
	background: #f5f5f5;
	padding: 30px 0 0;
	margin-top: 20px;

	p {
		text-align: center;
		font: normal 500 17px/1.4 'Noto Serif', Arial, serif;
	}
	p.firstline { font-size: 22px; color: $snMainColor; margin-bottom: 5px; }
	p.secondline { margin-bottom: 20px; }
	input[type=text],
	input[type=email] {
		height: 40px !important;
		line-height: 40px;
	}
	input[type=text] { width: 27% }
	input[type=email] { width: 36% }
	input[type=text]:focus,
	input[type=email]:focus {
		border-color: #AECCC6;
	}
	form {
		width: 900px;
		max-width: 100%;
		margin: 0 auto;
	}
	input[type=submit] { margin-top: -1px }
}
.clear__filters,
.hide__filters,
.hide__sidebar,
.generate__filters,
.category__loadmore {
		background: #ededed;
		clear: both;
		display: block;
		margin: 0 0 -20px 0;
		padding: 10px 15px;
		text-align: center;
		width: 100%;
		@include transition();

		i {margin-right: 7px}
		&:hover{ background: darken(#ededed, 5%); }
	}
.hide__sidebar { margin: 0}
.hide__filters {
	background: #fafafa;
	border: 1px solid #ddd;
	font-size: 16px;
	margin: 0;
}
.category__filters {
	margin-bottom: 20px;

	div {margin-top: 6px}
	label:first-child {font-style: italic}
	select {margin-right: 15px}
}
.category__tile {
	font: normal 500 36px/1.4 'Noto Serif', Arial, serif;
	margin-bottom: 25px;
}
.category__description {
	color: #0c0c0c;
	margin-bottom: 25px;
}
.category__description h2 {
	font-size: 15px;
	font-weight: normal;
}
.prouduct__image { height: auto }
form#xcolumns_cart {
    .category__products {
        .product {
            padding-left: 0 !important;
        }
    }
}
div.category__products {
    .product {
        .overlay_html {
            left: 16px !important;
        }
    }
}
.category__products {
	.pm_only {
		.prouduct__image {
			background: #ddd;
			opacity: 0.5;
		}
		.product__title, .product__description, .product__actions {
			opacity: 0.5;
		}
	}
	.product__readmore {
		border: 1px solid #ccc;
		margin-bottom: 20px;
		overflow: hidden;
		display: block;
	}
	.prouduct__image {
		padding: 10px 10px 5px;
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
		-webkit-transition: all .3s ease-out;
		-moz-transition: all .3s ease-out;
		-ms-transition: all .3s ease-out;
		-o-transition: all .3s ease-out;
		transition: all .3s ease-out;
		&:hover {
			-webkit-transform: scale(1.1);
			-moz-transform: scale(1.1);
			-ms-transform: scale(1.1);
			-o-transform: scale(1.1);
			transform: scale(1.1);
		}
	}
	.product__overlay {
		padding: 1px;
		position: absolute;
		margin: -5px;
		z-index: 1;
	}
    .favorite-icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 20;
        padding: 7px 25px;
        font-size: 24px;
        text-decoration: none;
    }
    @media (max-width: 767px) {
        .favorite-icon {
            padding: 7px 15px;
        }
    }
	h3 { margin: 0}
	.product__title {
		color: $sntext;
		display: block;
		font: normal 600 15px/1.3 'Open Sans', Arial, serif;
		margin-bottom: 15px;
		padding: 0 10px;
		&:hover { color: $snMainColor }
	}
	.product_desc_wrapper {
		margin-bottom: 35px;
	}
	.product__description {
		color: #7e7e7e;
		display: block;
		font: normal 400 14px/1.3 'Open Sans', Arial, serif;
		margin-bottom: 35px;
		padding: 0 10px;

		ul { margin: 0; padding-left: 15px }
	}
	.product__actions {
		padding: 0;
		text-align: right;
	}
	.promoproduct__cart {margin-right: 16px}
	.product {margin-bottom: 60px}
}
/* Product */
.productview__cart {
	bottom: auto;
	display: inline-block;
	position: relative;
	right: auto;
}
.tab-pane .productview__cart {
	position: relative;
	left: 0;
	right: auto;
}

#cart__form--bottom {
	float: left;
	width: 100%;
	display: block;
}
.productview {
	h1 {
		font: normal 300 35px/1.3 'Noto Serif', Arial, serif;
		margin: 30px 0;
	}
	h2 {
		font: normal 300 22px/1.3 'Noto Serif', Arial, serif;
		margin: 0 0 30px;
	}
	h1 + h2 {
		margin-top: -30px;
	}
	.productview__actions h2 {
		font: normal 600 15px/1.3 'Open Sans', Arial, serif;
	}

	.productview__navigation {
		overflow: hidden;
		margin-top: 10px;
		a {
			color: $sntext;
			float: right;
			font: italic 300 15px/1.3 'Noto Serif', Arial, serif;
			padding: 10px 15px;
			@include transition();
			&:hover {
				background: $snBlue;
				color: #fff;
				border-color: lighten($snBlue, 20%);
				i {color: #fff}
			}
		}
	}
	.tab-content>.tab-pane { overflow: hidden }
	.productview__tabs {
		margin-top: 30px;
	}
	.tab-content {
		border: 1px solid #d3d3d3;
		border-top: 0;
		padding: 45px 25px;
	}
	.nav-tabs>li { margin-right: 15px;}
	.nav-tabs>li>a {
		font-size: 15px;
		padding: 12px 20px;
		border: 1px solid #d3d3d3;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		-ms-border-radius: 0;
		-o-border-radius: 0;
		border-radius: 0;
	}
	.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
		border-color: #d3d3d3;
		border-bottom-color: transparent;
	}
	a.parent {
		float: left;
		background: #f4ebd7
	}
	a.parent i,
	a[rel="prev"] i { margin-right: 25px }
	a[rel="next"] i { margin-left: 25px}
	a[rel="prev"],
	a[rel="next"] { border: 2px solid $snYellow }
	a[rel="prev"] { margin-right: 10px }
	.galleryLoader{
		display: inline-block;
		font-size: 25px;
		top: 50%;
		position: absolute;
		left: 50%;
		margin: -14px 0 0 -14px;
		color: #939393;
	}
}
/* Regular product gallery - not in overlay */
div.overlay_html {
    text-align: center;
    padding: 5px;
    font-weight: bold;
    font-size: large;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    color: white;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    min-width: 65px;
    max-height: 65px;
    min-height: 65px;
    box-shadow: 0 0 4px 0 rgba(255, 255, 255, 1);
    span {
        margin-left: 3px;
        font-weight: normal!important;
        font-size: medium!important;
    }
}
.productview__gallery {
	border: 1px solid #676767;
	color: #676767;
	padding: 30px 30px 20px;
	position: relative;

	&:hover {
		filter: contrast(115%); -webkit-filter: contrast(115%);
		cursor: zoom-in;
		.gallery__overlay { top: 0 }
	}
	.gallery__overlay {
		display: block;
		position: absolute;
		z-index: 20;
		overflow: hidden;
		cursor: pointer;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-o-transition: all 0.5s;
	transition: all 0.5s;
		width: 100%;
		height: 80%;
		top: -100%;
		left: 0;
		.overlay__circle {
			display: block;
			position: relative;
			width: 75px;
			height: 75px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			background: rgba(255, 255, 255, 0.6);
		}
		.fa-search {
			font-size: 28px;
			color: #222;
			font-weight: 100;
			line-height: 75px;
			top: 0;
			text-align: center;
			display: block;
		}
	}
	@include transition()
}

.productview__product__overlay {
	position: absolute;
	padding: 8px;
	z-index: 999;
}
.productview__wrapper {
    padding-right: 15px;
    overflow: hidden;
    position: relative;
    .overlay_html {
        min-width: 90px;
        max-height: 90px;
        min-height: 90px;
        left: 10px;
        top: 10px;
        font-size: x-large;
    }
}
.productview__badges {
    display: flex;
}
.productview__badge {
    display: flex;
    flex-direction: column;
    text-align: center;
    > * {
        margin-right: 6px;
        padding-right: 6px;
        padding-bottom: 6px;
        text-align: left;
    }
}
.productview__tooltip {
	margin-left: 2px;
    cursor: pointer;
    opacity: 0.5;
	font-size: 15px;
    position: relative;
    top: 1px;
    text-transform: none;
    &:hover { opacity: 1; }
}
.productview .details { display: none }
.product__variants {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x;
    gap: 1rem;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
}
.variant__label {
    font: normal 600 15px/1.3 'Open Sans', Arial, serif;
    margin: 20px 0 7px;
}
.product__variant {
    scroll-snap-align: start;
    width: 165px;
    min-width: 165px;
    padding: 0 7px 3px;
    border: 1px solid #d3d3d3;

    &:hover {
        border-color: #852b2b;
        background: #f8ebd9;
    }

    p {
        margin: 10px 0 5px;
        text-align: center;
    }
}
.rating {
	display: block;
	width: 100%;
	min-height: 30px;
	margin: -15px 0 10px;

	.rating__stars {
		unicode-bidi: bidi-override;
		direction: rtl;
		text-align: left;
		float: left;
	}
	.rating__stars span.star {
		font-family: FontAwesome;
		font-weight: normal;
		font-style: normal;
		font-size: 18px;
		display: inline-block;
		-webkit-transition: all .2s ease-in;
		-moz-transition: all .2s ease-in;
		-ms-transition: all .2s ease-in;
		transition: all .2s ease-in;

		&:hover {
			-webkit-transform: scale(1.3);
			-moz-transform: scale(1.3);
			transform: scale(1.3);
		}
	}
	.rating__stars span.star:before {
		content: "\f006";
	    padding-right: 4px;
	    color: #ddd;
	}
	.rating__stars span:hover,
	.rating__stars span:hover ~ span {
		color: #ddd;
		cursor: pointer;
	}
	.rating__stars span.star.active:before,
	.rating__stars span:hover:before,
	.rating__stars span:hover ~ span:before {
		content: "\f005";
    	color: gold;
	}
	.rating__stars {
		height: 25px;
	}
	.rating__desc {
		font-style: italic;
		color: #777;
		font-size: 90%;
		padding-left: 10px;
		margin: 0;
		line-height: 19px;
        display: inline-block;
	}
    .rating__exp { display: inline-block; color: #777; }
	.rating__count { display: none !important }
}
.collection_regulation {
    font-style: italic;
    color: #777;
    font-size: 90%;
    margin: 0;
    display: inline-block;
}
.collection_regulation--basket {
    font-style: italic;
    color: #777;
    font-size: 70%;
    margin: 0;
	text-wrap: wrap;
}
.productview__actions {
	font: normal 400 15px/1.3 'Open Sans', Arial, serif;
    padding-left: 15px;
}
.productview__actions--wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    height: 100%;
}
.productview__form {
    display: flex;
    align-items: center;
    margin-top: auto;
    > * {
        flex: 1 0 50%;
        white-space: nowrap;
        box-sizing: border-box;
    }
    .basket-cta-row {
        display: block !important;
    }
    @media (max-width: 991px) {
        display: block;
    }
}
.productview__telephone { font-style: italic }
.product__omnibus {
    padding-top:10px;
    font-size: smaller;
}
.productview__price {
	bottom: 0;
	color: $snMainColor;
	font-size: 18px;
	left: 15px;
	margin: 0!important;
	padding: 0;
    padding-right: 10px;

    span{
        white-space: break-spaces;
        font-size: small;
    }

	strong {
		font-weight: 400;
		font-size: 27px;
	}
	.productview__fullprice {
		margin-bottom: 0;
		font-size: 17px
	}
}
.collection_info {
    white-space: normal;
}
.productview__price--installments {
	padding-top: 10px;
	min-width: 160px;
	max-width: 200px;

	div { margin-bottom: 0 }
	.regular__price {
		font-size: 27px;
		text-align: center;
	}
	span {
		background: #888;
		color: #fff;
		clear: both;
		display: block;
		font-size: 10px;
		padding: 5px;
		text-transform: uppercase;
		text-align: center;
		width: 100%;
	}
	.installments__amount,
	.installments__price {
		background: #fafafa;
		float: left;
		font-size: 90%;
		text-align: center;
		padding: 5px;
		width: 50%;
	}
	.installments__price {
		background: #eee;
	}
}
.productview__cart {
	font-size: 17px;
	padding: 17px 15px;
	text-align: center;
}
.control-group.email { width: 100%; margin-bottom: 10px; position: static; }
.control-group.fname,
.control-group.lname {
	width: 50%;
	float: left;
	margin-bottom: 15px;

	input { width: 100%; height: 40px !important; line-height: 40px }
}
.control-group.email label {width: 15%; }
.control-group.email input, .control-group.phone input { width: 100% !important; height: 40px!important }
.control-group.tos { margin-bottom: 10px }
.control-group.fname { padding-right: 10px }
.additional_tos { color: rgb(88,88,88); font: normal 400 14px 'Open Sans', Arial, serif !important; line-height: 30px!important; }
.actions__wrapper {
    display: flex;
    flex-flow: wrap;
    > * {
        flex: 0 0 50%;
    }
}
.control-group.controls--invalid {
	padding: 5px;
}
.notifyme__form {
	display: none;
	background: #fafafa;
	border: 1px solid #d3d3d3;
	margin-top: -40px;
	padding: 15px;
	position: relative;
    text-align: left;

	.productview__button { width: 100% }
	label.has-error {
		border: 0;
		font-size: 75%;
		margin: 0!important;
		display: inline;
		width: auto;
		font-weight: bold;
	}
	.notifyme__close {
		position: absolute;
		right: 15px;
		top: 10px;
		&:hover { color: #000; cursor: pointer }
	}
	.notifyme__progress {
		display: block;
		margin: 0 auto;
		padding-top: 20px;
		padding-bottom: 20px;
		width: 20px;
	}
}
.productview__intro {
	padding-bottom: 20px;
	overflow: hidden;
}
.stock {
	color: #333;
	font-size: 12px;
	padding: 2px 5px;
	border: 1px solid #333;
	text-transform: uppercase;
}
.productview__specification {
	border: 1px solid #d5d5d5;
	margin-bottom: 20px;
	overflow: hidden;
	padding: 25px;
	h5 {
		font: italic 400 22px/1.5 'Noto Serif', Arial, serif;
		margin: 0 0 30px 0;
	}
	dt,dd { margin-bottom: 8px; }
	dt {
		clear: left;
		padding: 0 20px 0 0;
	}
	dd { padding: 0}
}
.description__prices {
	padding-top: 100px;
	div,a { position: relative; display: block; margin-bottom: 20px!important; left: 0}
}
.productview__social {
	font: italic 400 15px/1.3 'Open Sans', Arial, serif;
	margin-bottom: 50px;

	a {margin: -5px 2px; font-size: 15px }
	i { color: #a3a3a3 }
}
.productview__tabs img { margin: 15px }
.productview__tabs img.pull-left { margin: 15px 15px 15px 0 }
.productview__tabs img.pull-right { margin: 15px 0 15px 15px }
.productview__tabs figure {
	background: #e3e3e3;
	border: 1px solid #ccc;
	display: inline-block;
	padding: 10px;
	margin: 15px;
	img { border: 1px solid #ccc; margin: 0}
}
.productview__tabs figure.pull-left { margin: 15px 15px 15px 0 }
.productview__tabs figure.pull-right { margin: 15px 0 15px 15px }
.productview__tabs figcaption {
	color: #888;
	font-size: 12px;
	margin-top: 5px;
	text-align: center;
	text-transform: uppercase;
}
.productview__tabs .btn { margin-bottom: 10px }

.com_content .related {
	h3 {
		font: italic 400 20px/1.5 'Noto Serif', Arial, serif;
		margin-top: 0;
		margin-bottom: 10px;
	}
	.related__description {
		padding-left: 7px!important;
		a {
			border: 0;
			font-size: 13px;
			line-height: 16px;
			color: #111;
			&:hover {
				background: none;
				border-bottom: 1px solid $snMainColor;
			}
		}
	}
	.related__product {
		padding: 7px 0;
		border-bottom: 1px solid #ddd;
	}
	.related__image img {
		width: 100%;
		height: auto;
	}
	.related__image a,
	.related__image a:hover {
		background: none;
		border: 0;
	}
	.related__products + .related__products { margin-top: 30px }
}

#sidebar {
	padding-left: 0;
	padding-right: 40px;
	font-size: 15px;

	h3 {
		color: $snMainColor;
		font-size: 20px;
		margin: 10px 0;
	}
	.menu {
		> li > a { font-weight: bold; padding-left: 5px!important }
		a { color: $sntext; display: block}
		li a {
			padding: 10px 15px 10px 0;
		}
		li { border-top: 1px solid #d5d5d5 }
		ul { margin: 0; padding: 0; list-style: none}
		.small { font-size: 100% }
		ul a { padding-left: 20px; }
		ul ul a { padding-left: 40px }
		ul ul ul a { padding-left: 50px }
		li.current a { background: #f2f2f2; font-weight: bold }
	}
}
.image__box h3,
.first .image__box h3 { background: $snMainColor}
.item0 .image__box h3 { background: #406072 }
.item1 .image__box h3 { background: #406072 }
.item2 .image__box h3 { background: #76a09c }
.item3 .image__box h3 { background: #7e8967 }
.item4 .image__box h3 { background: #6c4454 }
.item5 .image__box h3 { background: #c1a158 }

.image__box {
	max-width: 300px;

	h3,
	img { width: 100% }
	h3 {
		color: #fff;
		font: normal 600 14px/1.4 'Open Sans', Arial, serif;
		margin: 0 0 20px;
		padding: 20px 20px;
		text-align: center;
		text-transform: uppercase;
		vertical-align: middle;
	}
	a {color: #fff}
}

/* Basket */
.view-basket,
.view-checkout,
.view-thankyou {
	header {
		border-bottom: 1px solid #e5e5e5;
	}

    .finance-plan-select {
        width: 100%;
        margin: 10px 0px;
    }
    .disabled {
        background: #ddd;
        pointer-events: none;
        &:hover { background: #ddd }
    }
}
.small__text {
	color: #777;
	font-size: 90%;
	font-style: italic;
}

/* Contact form */
.contact-block,
.contact-form,
.contact-miscinfo {
	background: #f1f1f1;
	padding: 15px 20px;
	border: 1px solid #e5e5e5;
	margin-bottom: 15px;
	-webkit-box-shadow: 0 0 13px rgba(0, 0, 0, .07);
	-moz-box-shadow: 0 0 13px rgba(0, 0, 0, .07);
	-ms-box-shadow: 0 0 13px rgba(0, 0, 0, .07);
	box-shadow: 0 0 13px rgba(0, 0, 0, .07);
	-webkit-transition: all .5s ease-out;
	-moz-transition: all .5s ease-out;
	-ms-transition: all .5s ease-out;
	transition: all .5s ease-out;
}
.contact-miscinfo { overflow: hidden }
.contact-block {
	background: #373b48;
	color: #fff;
}

.contact-form {
	background: url('../img/contact_background.jpg');
}
.contact .col-md-6 { width: 50% }
.contact .col-md-6:first-child { padding-left: 0}
.contact .col-md-6:last-child { padding-right: 0}
.contact {
	h2,
	h3 {
		font: normal 400 25px/1.3 'Noto Serif',Arial,serif;
		margin: 0 0 30px 0
	}
	p { font-size: 110%; margin-bottom: 20px}
	dl {
		color: #abacb0;
		a { color: #fff }
	}
	.dl-horizontal dd { margin-bottom: 15px }
	.contact-street {
		font-style: italic;
		font-size: 110%
	}
	legend { font-size: 12px }
	input,
	textarea {
		  margin: 6px 0 0;
		  width: 80%;
		  height: auto!important;
	}
	.btn {margin-top: 15px; padding: 15px 20px; display: block;}
	.control-label { padding-top: 12px }
	#jform_contact_email_copy {float: left; width: 20px; margin-top: 4px !important}
}
/* Purchase list */
.purchase-item {
	clear: both;
	display: block;
	height: 34px;
	line-height: 34px;
	padding: 0 10px;
	font-size: 12px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	input[type="number"] { height: 26px !important; line-height: 22px; text-align: right }
	&:hover { background: #fafafa; border-top: 1px solid #ddd; border-bottom: 1px solid #ddd }
	label.has-error { padding: 0; max-width: 205px }
}
.purchasedetails {
	border: 1px solid #ddd;
	padding: 15px;
}

#purchaselistSubmit { margin: 15px 0 0 10px; float: left }
.well {
	.has-error {
		display: block;
		max-width: 205px;
	}
	.control-group {
		clear: both;
		display: block;
		overflow: hidden;
		padding-bottom: 5px;
		width: 100%;
	}
	.control-label {
		clear: left;
		float: left;
		width: 30%;
		line-height: 37px;
	}
	.controls {
		width: 70%;
		float: right;
		min-width: 100px;
	}
	.consent-label-full{
		.control-label {
			width: 100% !important;
			label {
				line-height: 22px;
				margin-bottom: 0;
			}
		}
		.controls {
			margin-top: 10px;
			margin-bottom: 20px;
		}
	}
}
.form-horizontal .radio { padding-left: 5px }
.panel-heading {
	cursor: pointer;
	i {
		float: right;
		color: #ccc;
		margin-top: 2px;
		-webkit-transition: -webkit-transform 0.2s linear;
	 	-moz-transition: -moz-transform 0.2s linear;
 		transition: transform 0.2s linear;
 		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		transform: rotate(0);
	}
	&:hover {
		i { color: $snMainColor; }
	}
}
.panel-heading.opened{
	i {
		color: $snMainColor;
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}
#purchase-list {
	margin-top: 20px;
	.label {
		display: inline-block;
		margin-bottom: 15px
	}
}
.label { border-radius: 0 }
.purchaseList .panel-body { padding: 10px 0}
.purchase-item > div {
	line-height: 34px;
	display: inline-block;
	label { margin-bottom: 0;}
}
.panel-body .panel-group { margin-bottom: 0 }
.panel-body .panel { margin-bottom: 8px }
.panel-body .panel-heading {
	font-size: 13px;
	padding: 5px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	border-radius: 2px;

	i { padding-right: 5px }

	h5 {
		font-size: 13px;
		&:hover { cursor: pointer }
	}
}
.col-md-8.purchase-label { padding-left: 0 }

/* Autocomplete search */
.autocomplete-suggestions {
	position: absolute;
	z-index: 99999;
	width: 402px;
	overflow: auto;
	border: 1px solid $snborder;
	background: #fafafa;
	.autocomplete-suggestion {
		padding: 0;
		white-space: nowrap;
		overflow: hidden;
		border-bottom: 1px solid $snborder;
		a {
			display: block;
		    width: 100%;
			color: $sntext;
			padding: 8px;
			text-overflow: ellipsis;
		    white-space: nowrap;
		    overflow: hidden;
		}
		&:hover { background: #F0F0F0; }
		&.category {
			padding: 8px;
			font-size: 12px;
			font-weight: bold;
			color: $snMainColor;
			background: #F0F0F0;
		}
		.right_link {
			padding: 2px;
			color: $sntext;
			float: right;
			font-weight: normal;
			font-size: 10px;
			text-decoration: underline;
			cursor: pointer;
		}
		&.prod:nth-child(n+5),
		&.cat:nth-child(n+5),
		&.art:nth-child(n+5){ display: none; }
	}
}
body.com_search {
	.autocomplete-suggestions {
		width: 1140px;
		max-width: 100%;
	}
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Useful suffixes */
.nomargin { margin: 0; padding: 0}
.height__fix {height: 100%}
.stock.low { border-color: #b40d00; color: #b40d00 }
.stock.medium { border-color: #e09e00; color: #e09e00 }
.stock.soldout { background: #ccc; color: #333; border-color: #aaa;margin-right: 10px; }
.stock.big { border-color: #7ad124; color: #7ad124 }
.fa-pinterest:hover {color: #C91F22 }
.fa-facebook-square:hover {color: #3A5795 }
.fa-envelope-o:hover {color: $snBlue}
.type {
	border: 1px solid #192B32;
	color: #192B32;
	font-size: 12px;
	padding: 2px 5px;
	text-transform: uppercase;
	border-radius: 0px;
    margin-bottom: 7px;
}
.type.single {
	border: 1px solid #2B804C;
	color: #2B804C;
}

/* Mobile menu */
.navigation--mobile {
	background: $snBlue;
	bottom: 0;
	color: #fff;
	display: block;
	min-width: 180px;
	max-width: $mobileMenuWidth;
	left: auto;
	position: fixed;
	right: 0;
	top: 0;
	width: 80%;
	-webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);

}
.navigation--mobile.opened{
	-webkit-transform: translate3d(0%, 0, 0);
    -moz-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}
.mobilemenu {
	text-transform: uppercase;
	a { color: #fff; &:hover { color: $snMainColor } }
	.nav > li {
		border-bottom: 1px solid lighten($snBlue, 5%)
	}
	.menu_active {
		background: darken($snBlue, 10%);
		color: #fff;
	}
	.nav li.menu_active {
		border-bottom: 1px solid $snMainColor;
		border-top: 1px solid $snMainColor;
		margin-top: -1px;

		a:hover { color: $snMainColor }
	}
	ul > li > ul {
		padding-left: 0px;
		li {
			font-size: 80%;
		    background: lighten($snBlue, 5%);
		    margin: 1px 0;
		    &.menu_active {
				background: darken($snBlue, 5%);
			}
			a {
				position: relative;
    			display: block;
		    	padding: 10px 30px;
		    	&:focus {
		    		background: #fff;
		    	}
			}
		}
	}
}
.mobilemenu--extended {
	text-transform: none;
	background: #424756;
	font-size: 80%;
	a { color: #ccc }
}

li.nav-submenu {
	display: none;
	&> ul > li { display: none; }
}

.submenu-icon {
    position: fixed;
    right: 7px;
    margin-top: -41px;
    padding: 10px;
	z-index: 100;
    font-size: 1.5em!important;
    color: #636b80;
	opacity: 0.7;
	transition-duration: 0.2s;
	&.collapsed {
		-webkit-transform: rotate(-90deg);
	    -moz-transform: rotate(-90deg);
	    -o-transform: rotate(-90deg);
	    -ms-transform: rotate(-90deg);
	    transform: rotate(-90deg);
	}
}

#nav-icon {
  width: 45px;
  height: 30px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: $snMainColor;
  border-radius: 6px;
  opacity: 1;
  right: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) { top: 0px }
#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) { top: 12px }
#nav-icon span:nth-child(4) { top: 24px; }
#nav-icon.open span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}
#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#nav-icon.open span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.view-category.layout-blog .cat-children > div { margin-bottom: 20px }
.mobilesearch.active { background: $snMainColor; color: #fff; }
img.lightbox {
  cursor: pointer;
}

.promoproduct__cart.promoproduct__cart--green{
	background: $snGreen;
	&:hover { background: $snBlue;}
}
.center{
	text-align:center;
}
.out_of_stock{
	position: absolute;
	bottom: 10px;
	right: -1px;
}
.go-shopping-cart{
	float:right;
	margin-bottom:15px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
}
.category__products .row:first-child{
	margin: 0 -15px 0 -15px;
}
.category__products .row:nth-last-child(2){
	margin: 0 -15px 0 -15px;
}
.editShipping .has-error{
	max-width: 100%;
}
/* blog layout */
hr.blog__separator {
	border-top: 2px solid #444;
	border-bottom: 1px solid #fff;
	width: 100%;
	&::after {
		content: '';
		display: block;
		margin-top: 2px;
		border-top: 1px solid #444;
		border-bottom: 1px solid #fff
	}
}
.article__intro .nopadding {
	padding-left: 0;
	padding-right: 0;
}
.item-page .blog__info { padding-top: 10px }
.page-header { margin-bottom: 10px }
.page-header a,
.item-page .page-header h1,
.item-page .page-header h2 {
	color: #222;
	text-decoration: none;
	font-weight: 400;
	text-align: left;
	line-height: 1.3em;
	margin: 0;
}
.page-header a:hover {
	&:hover {
		cursor: pointer;
		color: #222;
		text-decoration: none;
	}
}
.blog {
	* { white-space: normal }
	.page-header h1,
	> h2 {
		font-size: 20px;
		line-height: 1em;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-family: 'Open Sans',Arial,serif;
		color: #222;
		font-weight: 700;
		text-align: center;
	}
	> h2 {  font-size: 16px; }
	.item-image img {
		width: 100%;
		max-width: 100%;
		height: auto;
		display: block;
		margin: 0;
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
		-webkit-transition: all .3s ease-out;
		-moz-transition: all .3s ease-out;
		-ms-transition: all .3s ease-out;
		-o-transition: all .3s ease-out;
		transition: all .3s ease-out;

		&:hover {
			-webkit-transform: scale(1.05);
			-moz-transform: scale(1.05);
			-ms-transform: scale(1.05);
			-o-transform: scale(1.05);
			transform: scale(1.05)
		}
	}
	.item-image {
		overflow: hidden;
		width: 100%;
		max-width: 100%;
		margin: 0 0 15px;
	}
	.items-leading h2 { font-size: 42px }
	.items-leading .blog__introtext {
		font-size: 16px;
	}
	.items-leading > div {
		margin-bottom: 20px;
		overflow: hidden;
	}
	.items-row .page-header h2,
	.items-row .page-header a {
		font-size: 22px;
	}
	> .category-desc,
	.cat-children {
		color: #333;
		text-align: center;
		font: normal 200 18px/1.3 'Noto Serif', Arial, serif;
	}
	.items-row,
	.items-leading,
	.cat-children {
		display: block;
		width: 100%;
		clear: both;
	}
}
.blog--grid {
	.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
		padding: 0;
	}
	.item-image,
	.items-leading>div {
		margin: 0;
	}
	.item .blog__introtext,
	.item .page-header h2 {
		text-align: center;
	}
	.item .blog__introtext {
		font-size: 80%;
	}
	.item .page-header h2 {
		font-size: 90%;
	}
	.item__overlay {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0,0,0,0.75);
		overflow: hidden;
		width: 100%;
		height: 0;
		transition: .3s ease;
	}
	.item:hover .item__overlay,
	.leading:hover .item__overlay {
		height: 100%;
	}
	.item__wrapper {
		padding: 5% 10%;
		position: absolute;
		overflow: hidden;
		top: 50%;
		 transform: translate(0, -50%);
  		-ms-transform: translate(0, -50%);

		h2,
		h2 a,
		p.readmore a,
		.blog__info a {
			color: #fff;
			&:hover {
				color: #fafafa;
			}
		 }
		p.readmore a.btn:hover {
			background: $snMainColor;
			color: #fff;
		}
		.blog__introtext { color: #fafafa }
	}
	.leading { position: relative }
}
.tags.inline {
	list-style: none;
	padding: 0;

	li {
		display: inline;
		a {
			background: none;
			display: inline;
			padding: 0;
			font-weight: 400;
			&:hover {
				border-bottom: 1px dotted #222;
				text-decoration: none;
				background: none;
				color: #222;
			}
		}
		a::after { content: ', '; }
	}
}
.items-leading .blog__introtext,
.items-leading .blog__info,
.items-leading p.readmore,
.items-leading .page-header h2,
.items-leading .page-header a { text-align: center }
.blog__introtext,
.blog__info,
.items-more a {
	font: normal 200 15px/1.2 'Noto Serif', Arial, serif;
	color: #585656;
	margin-bottom: 10px;
}
.items-more a {
	color: #444;
	&:hover { color: #111; }
}
.blog__info,
.blog__info a {
	color: #bbb;
	font-size: 13px;
}
p.readmore {
	margin: 15px 0;
	overflow: hidden;
}
p.readmore a.btn {
	color: #585656;
	border-bottom: 1px dotted #bbb;
	background: none;
	padding: 5px 0;
	margin: 0 auto;
	text-align: center;
	display: inline-block;

	&:hover {
		border-color: #111;
		color: #111;
		background: #efefef;
	}
}
p.readmore a::after { content: ' >'; color: #bbb; }
.blog__info a:hover {
	color: #585656;
	border-bottom: 1px dotted #222;
	text-decoration: none;
	color: #222;
	cursor: pointer;
}
.blog__info a:hover a::after { color: #585656}
div.pagination { width: 100% }
ul.pagination { float: left; margin-top: 0; }
.cover__column {
	padding: 10px 30px 0 30px;
}
.full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}
.cover__wrapper{
	padding: 30px;
	background: #f1eeee;
	text-align: center;
}
.previous{
	border-top: 1px solid #ccc;
	margin-top:20px;
	padding: 30px 30px 0 30px;
	background: #f1eeee;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
}
.previous img{
	width:80%;
}

.overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000000;
	overflow: hidden;
	width: 100%;
	height: 0;
	transition: .3s ease;
	opacity: 0.8;
}
.cover_overlay:hover .overlay {
	height: 100%;
}
.overlay_text {
	color: white;
	font-size: 15px;
	margin: 0 auto;
	margin-top:20px;
	text-align: center;
}
.overlay .previous_btn{
	display: block;
	opacity: 1 !important;
	margin-top: 10px;
}
.overlay .transparent{
	background:none;
	border:solid 2px #852b2b;
}
.overlay .transparent:hover{
	background: #852b2b;
}
.previous__column{
	margin-bottom:30px;
}
.title_small {
	font-size:12px;
	color:#6d6d6d;
}

.cover__wrapper a {
	padding: 10px;
	background: #F2F2F2;
	-webkit-box-shadow: 8px 8px 5px 0px rgba(131,131,132,1);
	-moz-box-shadow: 8px 8px 5px 0px rgba(131,131,132,1);
	box-shadow: 8px 8px 5px 0px rgba(131,131,132,1);
}
.content__column {
	padding: 0 5%!important;
	.page-header {
		font-size: 20px;
		line-height: 1em;
		text-transform: uppercase;
		font-family: 'Open Sans',Arial,serif;
		color: #222;
		text-align: left;
		font-weight: 700;
	}
	.category-desc {
		text-align: left;
		font-size: 15px;
	}
}
.buttons__column {
	padding-top: 20px;
	.btn {
		width: 100%;
	}
}
.item-page .item-image,
.item-page .item-image img {
	width: 100%;
	height: auto;
	display: block;
}
.item-page .item-image { margin-bottom: 15px;}
.item-page a {
	border-bottom: 1px dotted #444;
	background: none;

	&:hover {
		border-color: #111;
		color: #111;
		background: #efefef;
	}
}
.item-page .article-intro a:not([href$=jpg]):not([href$=jpeg]):not([href$=jpe]):not([href$=png]):not([href$=gif]){
    -webkit-transition: all .1s ease-out;
    -o-transition: all .1s ease-out;
    transition: all .1s ease-out;
    background-position: 0 20px;
    background-repeat: no-repeat;
    color: #020099!important;
    border: 0;
    text-decoration: none;
    background: none;

	&:hover {
			background: none;
    	    -webkit-box-shadow: inset 0 -1px 0 #fff, inset 0 -2px 0 #020099;
   			box-shadow: inset 0 -1px 0 #fff, inset 0 -2px 0 #020099;
    		text-shadow: -1px 0 0 #fff, -2px 0 0 #fff, -3px 0 0 #fff, -4px 0 0 #fff, 1px 0 0 #fff, 2px 0 0 #fff, 3px 0 0 #fff, 4px 0 0 #fff;
    }
}
.page-header a {
	border: none;
	&:hover {
		border: none;
		color: #111;
		background: none;
	}
}
.row-snrecaptcha {
	height: 0;
	visibility: hidden;
	display: block;
	padding: 10px;
	margin: auto;
}
.row--paymentmessage {
	text-align: center;
 	i {
		font-size: 50px;
		display: block;
		margin: 0 auto;
		text-align: center;
 	}
 	h4 {
		text-align: center;
 	}

 	.fa-exclamation-triangle { color: #E16428 }
 	.fa-info-circle { color: #1E56A0 }
 	.fa-check-circle { color: #0b0 }
 	.fa-exclamation-circle { color: #CD3131; }
 	.fa-hourglass-half { color: #E16428 }
 	a {
	    background-image: linear-gradient(to bottom,transparent 0,#852b2b 0);
	    transition: all .1s ease-out;
	    background-position: 0 20px;
	    background-repeat: no-repeat;
	    color: #222!important;
	    padding: 0 .125rem;
	    font-weight: 700;
	    border-bottom: 0;
	    &:hover {
	    	background-position: 0 0;
	    	color: #fff!important;
	    	text-decoration: none!important;
	    	border: none;
	    }
 	}
 	.badge {
 		background: #efeeef;
    	color: #76787c;
    	font-weight: 500;
 	}
}
.row__invoice {
	text-align: left;
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 10px;
	padding-bottom: 10px;
	h4 { line-height: 2em }
	.badge {
		float: left;
		margin-top: 18px;
	}
}
.row__invoice:last-child {
	border-bottom: 0;
}
.modal-links {
	padding: 0px 30px 0px 30px;
	margin-bottom: 0px;
	line-height: 25px;
}

.ssn-icon {
	font-size: 17px;
	color: darkgray
}
#checkOutDiv {
	min-width: 300px;
}

table.vat {
width: 100%;
	td {
		padding-left: 0px;
		padding-right: 0px;
		font-size: 90%;
	}
}

/* collapsable VAT section */
.vat__accordion {
    background-color: #fff;
    cursor: pointer;
    padding: 1em 0;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 15px;
    text-decoration: underline;
}

.vat__accordion.active, .vat__accordion:hover {
    background-color: #fff;
    color: #111; }

.vat__accordion:after {
    content: '\02795';
    /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px; }

.vat__accordion.active:after {
    content: "\2796";
    /* Unicode character for "minus" sign (-) */ }


body.nl-BE,
body.nl-NL,
body.fr-FR {
    .nl-nl-payment-icons,
    .payment .control-group .control-group img{
        inline-size: auto;
        float: initial;
        margin-right: 5px;
    }
    .nl-nl-payment-icons {
        max-width: 60px;
        inline-size: auto;
        margin-right: 5px;
        margin-bottom: 5px;
        height: auto;
    }
    .payment .control-group .control-group label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
	.products__filters-inner {
		margin: 20px 0px 0 0;
	}
	#jform_password2-lbl {
		line-height: 20px;
		margin-bottom: 0;
	}
	.contact-form {
		background: url('../img/contact_background-nlbe.jpg');
	}
	#shipping-payments .control-group{
		overflow: visible;
	}
	.mastercard__logo { display: none }
	.visa__logo {
		display: none;
		background: none;
	}
	.guarantees {
		padding-left: 0px;
		padding-right: 0px;
		font-size: 93%;
	}
	.guarantee__icon {
		padding: 0 5px 0 0;
		width: 30%;
	}
	.guarantee__icon img {
		margin-top: -5px;
	}
	.privacy_policy {
		color: #111;
		font-weight: 400;
		margin-left: -10px;
	}
}
body.nl-BE, body.fr-FR {
	.item-page h2 {
		font-size: 18px !important;
	}
}
body.hu-HU {
    .payment div.cod_installments{
        select {
            width:120px;
            border-radius: 3px;
        }
        label {
            padding:0 0 5px 0;
            color: #3c3b3b;
        }
    }
}
.infobar__text{
	.fa{
		color: #00AD00;
		font-size: 1.4em;
	}
	.fa:nth-child(2){
		margin-left:10px;
	}
}
#aside { padding-right: 0; padding-left: 30px }
#remindme-form, .account__actions { margin-top:20px; }

@keyframes fade-in-move-down {
	0% {
		opacity: 0;
		transform: translateY(-2rem);
	}
	100% {
		opacity: 0.6;
		transform: translateY(0);
	}
}
@keyframes fade-in-move-up {
	0% {
		opacity: 0.6;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(-1.5rem);
	}
}
.scroll_down {
	position: fixed;
    bottom: 10px;
    padding: 0;
    right: 0;
    opacity: 0.6;
    &.scroll_show {
    	opacity: 0.6;
    	animation: fade-in-move-down 0.7s;
    }
    &.scroll_hide {
    	opacity: 0;
    	animation: fade-in-move-up 0.3s;
    }
}
@media (max-width: 560px) {
	.mostpopular__inner {
		.promoproduct__image {
			max-width: 80vw;
		}
		.promoproduct{
			a{
				&.promoproduct__title{
					min-height: 76px;
				}
			}
		}
	}
}
@media (min-width: 561px) and (max-width: 768px) {
	.mostpopular__inner {
		.promoproduct__image {
			max-height: 25vw;
			height: 25vw;
		}
		.promoproduct{
			a{
				&.promoproduct__title{
					min-height: 76px;
				}
			}
		}
	}
}

@media (min-width: 769px) and (max-width: 1170px) {
	.mostpopular__inner {
		.promoproduct {
			padding: 15px 15px;
			.promoproduct__actions {
                position: initial;
				margin: 0;
				text-align: center;
			}
			.promoproduct__price {
				display: inline-block;
				bottom: 50px;
				margin: 10px 0 10px 0 !important;
			}
			.promoproduct__cart {
				position: relative;
				width: 100%;
				bottom: 0;
				left: 0;
				display: inline-block;
			}
		}
	}
}

@import 'gallery.scss';
@import 'search.scss';
@import 'basket.scss';
@import 'mypage.scss';
@import 'medium-desktop.scss';
@import 'bootstrap-medium.scss';
@import 'bootstrap-middle-medium.scss';
@import 'bootstrap-small.scss';
@import 'bootstrap-small-phone.scss';
@import 'cookie.scss';
@import 'pl.scss';
@import 'hu.scss';
@import 'cz.scss';
@import 'uk.scss';
@import 'ie.scss';
@import 'se.scss';
@import 'baltics.scss';
@import 'magic-check.scss';
@import 'lightbox.scss';
@import 'header.scss';

/* For print only */
@import 'print.scss';


/* add to cart products modal */
#add_to_cart_products_modal {
	@media (max-width: 1024px) { display: none!important; }
	&> .modal-dialog {
		width: 80%!important;
		min-width: 960px;
		max-width: 1140px;
		margin-top: 0;
		.modal-header {
			#system-message-container p { margin-bottom: 0 }
			h4 { font: normal 300 22px/1.3 'Noto Serif', Arial, serif }
		}
		.modal-body {
			margin-bottom: 0;
			padding-bottom: 0;
			.products__wrapper {
				overflow: hidden;
				position: relative;
				width: 100%;
				.product {
					margin-bottom: 30px;
					margin-top: -10px;
					padding: 10px;
					img.prouduct__image { background: #fff }
					.product__title { height: 3em }
					.product__actions {
						text-align: left;
						padding-top: 20px;
						form { margin-top: 10px }
					}
					.atc_modal__submit {
						float: right;
						position: absolute;
					    bottom: 0;
					    right: 15px;
					}
					.magic-checkbox + label { text-align: left }
				}
			}
		}
		.modal-footer a { padding: 10px }
	}
}
.modal {
	background: #00000080;
	button.close {
		transition: all 0.15s ease-in-out;
		margin-top: -5px;
		padding-right: 0;
	}
}
.btn-loading {
	opacity: 0.5;
	pointer-events: none;
}
#scrollTopButton {
  display: inline-block;
  background-color: #373b48;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: background-color .3s,
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#scrollTopButton::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 1.8em;
  line-height: 40px;
  color: #fff;
}
#scrollTopButton:hover {
  cursor: pointer;
  background-color: #333;
}
#scrollTopButton:active {
  background-color: #555;
}
#scrollTopButton.show {
  opacity: 0.9;
  visibility: visible;
}
#basketPopup {
    .modal-dialog {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .modal-header {
            border-bottom:solid 1px #983c38;
            margin: 0 20px;
            h3, h5 {
                margin-right:20px;
                display: inline;
            }
            h5 {
                font-size: 14px;
            }
        }
        .modal-body {
            margin: 25px 0 0 0;
            padding: 0 5px 20px 5px;
        }
        .close {
            font-size: 22px;
            position: absolute;
            top: 8px;
            right: 18px;
			background: transparent;
			&:hover {
				color:black;
			}
        }
        .button-link {
            background: none;
            color: #333;
        }
        .product-name {
            font-weight: bolder;
            margin-bottom: 5px;
        }
        .productPlace {
            min-height: 70px;
        }
    }
}
.pt20{
    padding-top:20px;
}
.fleft{
    float: left;
}
.fright{
    float: right;
}
@media (max-width: 1000px){
    .modal-dialog {width:60%;}
}
@media (max-width: 768px){
    .modal-dialog {
        width:70%;
        h3 {display: none}
        .button, button{font-size:12px;}
    }
}
@media (max-width: 560px){
    .modal-dialog {width:85%;}
}
@media (max-width: 425px){
    .modal-dialog {width:95%;}
}
//Loader
.lds-ellipsis {
    margin:0 auto;
    display: inline-block;
    position: relative;
    height: 65px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #983c38;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/* Returns screen */
.return {
	&__wrapper {
		display: block;
		background: rgba(245,248,253,.74);
		border: 1px solid #ededed;
		padding: 20px;
		border-radius: 3px;
	}
	&__form {
		display: block;
		width: 400px;
		max-width: 100%;
		margin: 30px auto;
	}
	label {
		display: block;
	}
	input {
		line-height: 45px;
		height: 45px!important;
		font-size: 20px;
		display: block;
		width: 100%;
	}
	.btn {
		width: 100%;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.form-group:not(:first-child) {
		margin-top:20px;
	}
	label.lerror {
		margin: 5px 0 10px 0 !important;
		text-align: center;
		color: #843534;
		border: 1px solid #843534;
		padding: 5px;
	}
	label.lsuccess {
		margin: 5px 0 10px 0 !important;
		text-align: center;
		padding: 5px;
		background-color: #dff0d8;
		border-color: #d6e9c6;
		color: #3c763d;
	}
}
// .form-group #invoice {
// 	width: 200px;
// }
// .form-group #securityCode {
// 	width: 300px;
// }
// .form-group #iban {
// 	width: 525px;
// }
.iban-hide {
	display: none;
}

#registerUser {
	margin-top: 10px;
}
#passwordSetError{
	padding:10px 0;
}
.btn--validate {
    width: 50%;
    border: 2px solid #f4ebd7;
    background: #f4ebd7;
    color: #111;
    padding: 6px 10px;
    text-align: center;
    box-sizing: border-box;
    font-size: 13px;
    font-style: normal;
    margin-top: 4px;
    max-width: 250px;
}
.btn--validate:last-child {
    width: calc(50% - 7px);
    margin-left: 7px;
}
.btn--inverted {
    border-color: #f4ebd7;
    background: #fff;
    color: #111;
}

.productview__actions .stickyMobileBottomBar {
	min-width: 50%;
    button.productview__cart {
		width: 100%!important;
		max-width: 100%!important;
		float: none;
		margin: 0!important;
	}
}
.basket__recurring_payment {
    margin: 0px;
    padding: 5px;
    display: none;
}
.form-container {
    margin-left: 25%;
    margin-right: 25%;
}
.form-worldpay {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.form-payinvoice-button {
    display: flex;
    justify-content: center;
}
.alert-no{
    display: none;
    color: #856404;
    background-color: #fff3cd;
    border: solid 1px #ffeeba;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
}
img.tiles-image{
    transition-property: transform;
    transition-duration: 0.3s;
    padding: 0 0 20px 0;
    &:hover{
        transform: scale(1.2) translate(0%, 0%);
    }
}
.basket{
    .row--message {
        width: 100%;
        padding: 20px 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        .row--message-text {
            margin-left: 15px;
            h3 {
                margin: 5px 0;
            }
        }
        @media screen and (max-width: 991px) {
            padding: 5px 0;
            text-align: left;
            flex-direction: row;
        }
        .fa-check-circle {
            color: #00bb00;
            font-size: 50px;
            display: block;
            margin: 0 auto;
            text-align: center;
        }
        .badge{
            display: inline-block;
            min-width: 10px;
            padding: 3px 7px;
            font-size: 12px;
            line-height: 1;
            vertical-align: baseline;
            white-space: nowrap;
            text-align: center;
            border-radius: 10px;
            background: #EFEEEF;
            color: #76787C;
        }
    }
    ul{
        list-style-type: none;
        margin: 0;
        padding: 0 0 10px 0;
    }
    legend{
        margin: 15px 0 15px 0;
    }
    table.basket-details td{
        padding: 8px!important;
    }
}
body.da-DK {
    #ov-onskeskyen-generated-wish-button{
        margin-top:20px;
        font-size: smaller;
    }
}
.messageNumberIgnore{
    cursor: pointer;
    color:red;
    text-decoration: underline;
}
.address-success{
    display: none;
    background: #F2F7FB;
    padding: 10px;
    margin-right: 10px;
    border-radius: 2px;
    span{
        cursor: pointer;
        color: #4bbcf4;
        margin-left:10px;
    }
}
#address-notfound, #address-empty{
    color: #e15656;
}

.fi-flex{
	display: flex;
	flex-direction: row;
}
.fi-flex :first-child {
	width: 300px;
}
.fi-flex > * {
	flex: 0 0 auto;
}
.fi-strong-authentication{
	padding: 20px 20px 20px 20px;
	margin-left:50px;
	img{
		width: 100%;
		max-width: 400px;
		height: auto;
		display: block;
		margin-top:20px;
	}
}
.basket-strong-auth-info, .basket-ssn-help{
	font-size: small;
	font-style: italic;
	color: #4a4949;
	img{
		display: none;
	}
}
span.payment_invoice {
	input.has-error {
		border: 1px solid #ff5959;
		background-color: rgba(255, 89, 89, 0.02);
		box-shadow: 0 0 3px 0 rgba(255, 89, 89, 0.3);
	}
}
#jform_snprofile_ssn_1-error {
	border:none;
	color: #ff5959;
	margin:0!important;
	padding:0;
	text-align: left;
	font-style: italic;
}
@media (max-width: 800px) {
	.fi-flex {
		flex-direction: column;
	}
	.fi-strong-authentication{
		margin-left:0;
	}
}

.filters-desktop {
    display: none;
}

.filters-mobile {
    display: block;
}

@media (min-width: 768px) {
    .filters-desktop {
        display: block;
        margin-right: 30px;
        margin-top: 20px;
        width: 90%;
    }

    .filters-mobile {
        display: none;
    }
}

@import 'togglePasswordVisibility.scss';
@import 'payByLink.scss';
@import 'autocomplete';
@import 'worldpay';
@import 'emaerketCertificateDK.scss';
@import 'notifyMeForm';
@import 'recommended';
@import 'blog';
@import 'mauticform';
@import 'topBanner';
