// max 992px

@media screen and (max-width: $mediumDevice) {
	main img { height: auto !important }
	.main__inner .magazine__article--secondary:last-child { display: none; }
	.basket__table .product__usp { display: none}
	.checkout__row>div:last-child { width: 50% }
	#member-registration .control-group .controls,
	#member-registration .control-group .control-label {width: 100%}
	.handel__logo { display: none }
	.site-search .search-query { width: 290px!important }
	.hbox__module img { width: 100% }
	.main__inner .magazine>.col-md-9 { padding-right: 0; border: 0;}
	.magazine__column.hidden-xs { display: none }
	.magazine__article--secondary { padding-top: 10px!important }
	.magazine>.col-md-9 { width: 100% }
	.view-basket header { padding: 10px 0 0}
	body .header__inner .header__row { min-height: 55px }
	body.isFrontpage .header__inner .header__row { min-height: 95px }
	body.landing-page .header__logo { width: auto }
	.autocomplete-suggestions { width: 342px; }
	body.com_search .autocomplete-suggestions { width: 100%; }
}

@media screen and (max-width: 800px) {
	.guarantees { display: none }

}
