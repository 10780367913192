.notifyme__form {
    display: none;
    background: #fafafa;
    border: 1px solid #d3d3d3;
    padding: 22px;
    position: relative;
    #jform_captcha {
        margin-bottom: 10px;
        & > div {
            box-shadow: none !important;
        }
    }
    .alert {
        padding: 0.5em;
        margin-bottom: 10px;
        font-size: 14px;
        color: #fff;
    }
    .alert-error {
        background-color: #e47f3c;
    }
    .alert-success {
        background-color: #98ba4e;
    }
    .tos label {
        max-width: calc(100% - 30px) !important;
        &.has-error {
            display: block;
            max-width: none!important;
            text-align: left;
            padding: 0;
        }
    }
    .control-group.email label {
        width: auto !important;
    }
    #jform_tos_consent-lbl {
        cursor: pointer;
    }
}
.notifyme__form .notifyme__close {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
}

.view-product .notifyme__form {
    margin-top: 5px;
}
body.nl-NL, body.fr-FR, body.nl-BE {
    .productview__ask {
        .btn {
            margin-top: 15px;
        }
    }
}
label#jform_tos_consent-lbl{
    font-size: small;
}
.lp{
    white-space: normal !important;
}
.notify_me_btn{
    width: fit-content;
    border:solid 1px #5a1c1c;
    border-radius: 1px;
}
.notify_me_short{
    display: none;
}
.notify_me_long, .notify_me_short{
    padding: 1px 10px;
}
@media screen and (max-width: 680px) {
    .notify_me_short {
        display: inline;
    }
    .notify_me_long {
        display: none;
    }
}
