// vipps
.vippsIcon,
.vippsText {
    vertical-align: middle;
    display: inline-block;
}

.vippsText {
    margin-left: 7px;
    margin-top: -8px;
}
.handel__logo,
div.handel__wrapper {
    float: right;
    max-width: 47px;
}

html[lang=nb-no] {
    .form .radio label[for^=payment] {
        display: block;
    }
}

.button-loading-payment {
    opacity: .5;
    cursor: wait !important;
}

.cancel-payment-vipps-wrapper {
    text-align: center;
    margin-bottom: 20px;
    .cancel-payment-vipps {
        font-size: 12px;
    }
}

.alert-vipps-error {
    margin: 0 0 15px 0 !important;
    ul {
        background: unset;
        border: unset;
        list-style: unset;
        margin: 0;
        padding: 0 0 0 20px;
    }
}
