body.hu-HU {
    .checkout__logos {
        padding-top: 15px;
        p {
            text-align: right
        }
    }
    .mastercard__logo {
        display: none
    }
    .visa__logo {
        background: url('../img/payment_icons/simple.png');
        width: 132px;
        height: 35px;
        margin-top: 0;
    }
    .payment>.control-group {
        overflow: visible
    }
    .guarantee>span {
        display: block;
        margin-bottom: 3px;
    }
    .handel__logo {
        display: none
    }
    .contact-form {
        background: #fff;
    }
    .notifyme__form #jform_tos_consent {
        vertical-align: top;
        margin-top: 0;
    }
    .notifyme__form #jform_tos_consent-lbl {
        max-width: 95%;
    }
    .basket__table input[type=checkbox]+label {
        font-size: 14px;
        line-height: 18px;
    }
    .basket__table .tos_label a {
        display: block;
    }
    .copyright__text {
        font-size: 80%;
        margin-bottom: 0
    }
    .panel_cookie p {
        font-size: 12px;
        white-space: inherit;
        margin: 2px 0 0;
        line-height: 1.8em;
        margin: 7px 0;
    }
    .productview__product__overlay {
        padding: 0;
    }
}

body.hu-HU.landing-page .soldouttext__readmore {
    white-space: pre-wrap
}

body.hu-HU.view-profile .account__label {
    font-size: 140%
}

body.hu-HU.view-basket,
body.hu-HU.view-checkout {
    .guarantees {
        margin-top: 0;
        padding: 0;
    }
    .guarantee {
        padding-right: 10px
    }
}

body.isFrontpage.hu-HU {
    .bottom h2 {
        font-size: 24px
    }
}

@media (min-width: 968px) {
    body.hu-HU .contact-address.dl-horizontal dt {
        width: 170px
    }
    body.hu-HU .contact-address.dl-horizontal dd {
        margin-left: 190px
    }
}

@media (max-width: 968px) {
    body.hu-HU .contact-address.dl-horizontal dt {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
    }
    body.hu-HU .contact-address.dl-horizontal dd {
        margin-left: 0
    }
}