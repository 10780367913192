.topbar {
    background: #fff;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .26);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, .26);
    box-shadow: 0 0 8px rgba(0, 0, 0, .26);
    min-height: 70px;
    left: 0;
    position: fixed;
    top: -200px;
    width: 100%;
    z-index: 1001;

    @include transition();

    .navigation {
        background: #fff;
        clear: both;
        display: block;
    }

    .menu__layout {
        background: #fff;
        border: 0;
        margin-top: 0;
        padding-right: 50px;
        position: relative;
    }

    .header__cart {
        position: absolute;
        height: 40px;
        margin: 0;
        right: 0;
        width: 40px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;

        .fa {
            font-size: 22px;
            line-height: 40px;
            margin-left: 9px;
        }
    }

    .menu__item {
        font: normal 600 15px/40px 'Open Sans', Arial, serif;
        height: 40px;
    }

    .infobar__inner p {
        margin-bottom: 0 !important;
    }
}

.topbar.active {
    top: 0 !important;
}

.infobar__inner p {
    margin-bottom: 10px !important;
}
