body.cs-CZ {
	.topbar .menu__item { padding: 0 20px }
	.checkout__logos {
		padding-top: 15px;
		p { text-align: right }
	}
	.mastercard__logo { display: none }
	.visa__logo {
		background: url('../img/payment_icons/payu.png');
		width: 100px;
		height: 50px;
		margin-top: -10px;
	}
	.payment > .control-group { overflow: visible }
	.guarantee > span { display: block; margin-bottom: 3px; }
	.handel__logo { display: none }
	.contact-form {
		background: url('../img/contact_background-cz.jpg');
	}
	.notifyme__form #jform_tos_consent {
		vertical-align: top;
		margin-top: 0;
	}
	.notifyme__form #jform_tos_consent-lbl {
		max-width: 95%;
	}
	.basket__table input[type=checkbox]+label {
		font-size: 14px;
		line-height: 18px;
	}
	.basket__table .tos_label a { display: block;}
	.basket__table input[type=checkbox]+label:before {
		height: 100px;
	}
	.copyright__text { font-size: 80%; margin-bottom: 0;}
}
body.cs-CZ.view-basket,
body.cs-CZ.view-checkout {
	.guarantees {
		margin-top: 0;
		padding: 0;
	}
	.guarantee { padding-right: 10px }
}
body.isFrontpage.cs-CZ {
	.bottom h2 { font-size: 24px }
}
@media (min-width: 968px){
	body.cs-CZ .contact-address.dl-horizontal dt { width: 170px }
	body.cs-CZ .contact-address.dl-horizontal dd { margin-left: 190px }
}
@media (max-width: 968px){
	body.cs-CZ .contact-address.dl-horizontal dt { width: 100%; text-align: left; margin-bottom: 10px; }
	body.cs-CZ .contact-address.dl-horizontal dd { margin-left: 0}
}

body.sk-SK {
	.topbar .menu__item { padding: 0 20px }
	.checkout__logos {
		padding-top: 15px;
		p { text-align: right }
	}
	.mastercard__logo { display: none }
	.visa__logo {
		background: url('../img/payment_icons/payu.png');
		width: 100px;
		height: 50px;
		margin-top: -10px;
	}
	.payment > .control-group { overflow: visible }
	.guarantee > span { display: block; margin-bottom: 3px; }
	.handel__logo { display: none }
	.contact-form {
		background: url('../img/contact_background-sk.jpg');
	}
	.notifyme__form #jform_tos_consent {
		vertical-align: top;
		margin-top: 0;
	}
	.notifyme__form #jform_tos_consent-lbl {
		max-width: 95%;
	}
	.basket__table input[type=checkbox]+label {
		font-size: 14px;
		line-height: 18px;
	}
	.basket__table .tos_label a { display: block;}
	.basket__table input[type=checkbox]+label:before {
		height: 100px;
	}
	.copyright__text { font-size: 80%; margin-bottom: 0;}
}
body.sk-SK.view-basket,
body.sk-SK.view-checkout {
	.guarantees {
		margin-top: 0;
		padding: 0;
	}
	.guarantee { padding-right: 10px }
}
body.isFrontpage.sk-SK {
	.bottom h2 { font-size: 24px }
}
@media (min-width: 968px){
	body.sk-SK .contact-address.dl-horizontal dt { width: 170px }
	body.sk-SK .contact-address.dl-horizontal dd { margin-left: 190px }
}
@media (max-width: 968px){
	body.sk-SK .contact-address.dl-horizontal dt { width: 100%; text-align: left; margin-bottom: 10px; }
	body.sk-SK .contact-address.dl-horizontal dd { margin-left: 0}
}
