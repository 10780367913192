.toggle-password {
	background: transparent!important;
    font-size: 18px;
    padding: 0;
	transition: none;
	position: absolute;
	height: 39px;
	border: none;
	width:20px;
	&:hover {
		background: none;
	}
	&:focus {
		outline: none;
	}
	i {
		color: #797979;
		width:20px !important;
	}
}
