@import 'variables';

.header__row-middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 10px;
    .header__row-middle-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .site-search {
            max-height: 49px;
            form {
                display: flex;
            }
        }
        .hmenu {
            height: 47px;
            width: 40px;
            display: none;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }
        .mobilesearch {
            display: none;
        }
    }
    .header__phone {
        font: 600 19px/1.4 "Open Sans", Arial, serif;
        color: #373b48;
        white-space: nowrap;
        .fa {
            font-size: 20px;
            margin-right: 8px;
        }
    }
    .header__logo {
        width: 200px;
        max-width: 40%;
        img {
            height: 80px;
            width: auto;
            max-width: 100%;
            object-fit: contain;
        }
    }

    .site-trust {
        width: 55px;
    }

    .handel__logo {
        margin: 0 0 0 15px;
    }
}

.header__item {
    margin-left: 15px;
    &:first-child {
        margin-left: 0 !important;
    }
}

.header__row-mobile-search .site-search {
    display: none;
    height: auto;
    padding: 10px;
}

.header__cart {
    .fa {
        margin-top: 4px;
    }
    .cart__counter {
        background: #f6f5f5;
        border: 2px solid #d7d7d7;
        color: $snMainColor;
        font-size: 10px;
        font-weight: bold;
        display: block;
        float: right;
        height: 21px;
        line-height: 16px;
        position: absolute;
        top: -5px;
        right: 0;
        text-align: center;
        width: 21px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
    }
}

body.fr-FR, body.nl-NL, body.nl-BE {
    .header__cart {
        .cart__counter {
            color: $button-main-green;
        }
    }
}

#nav-icon {
    width: 45px;
    height: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: $snMainColor;
    border-radius: 6px;
    opacity: 1;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
    top: 12px;
}

#nav-icon span:nth-child(4) {
    top: 24px;
}

#nav-icon.open span:nth-child(1) {
    top: 12px;
    width: 0%;
    left: 50%;
}

#nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
    top: 12px;
    width: 0%;
    left: 50%;
}

.active {
    .header__item-rounded {
        display: initial;
    }
}

.header__item-rounded {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $snMainColor;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 22px;
    color: #fff;
    -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;
    &:hover,
    &:active,
    &:focus {
        color: #fff;
        background: $snBlue;
    }
}

body.fr-FR, body.nl-NL, body.nl-BE {
    .header__item-rounded {
        background: $button-main-green;
        &:hover {
            background: $snBlue;
            color: #fff;
        }
    }
}

body.cs-CZ, body.sk-SK {
    .header .header__row-middle .header__logo {
        width: 270px;
        img {
            width: 270px;
            height: auto;
        }
    }
}

.header__popup {
    background: #fff;
    border: 2px solid #fff;
    padding: 20px 15px;
    position: absolute;
    top: 70px;
    left: -325px;
    width: 400px;
    z-index: 99999;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.75);
    h5 {
        font-size: 16px;
        margin: 0 0 10px 0;
    }
    .row {
        border-top: 1px solid #ccc;
        padding: 10px 0;
        &:last-child {
            padding: 0;
        }
    }
    .header__popup-close {
        position: absolute;
        top: 15px;
        right: 15px;
        background: none;
        font-size: 16px;
        padding: 0;
        i {
            color: #333;
        }
    }
    .header__popup-list {
        padding: 16px 12px;
        a {
            transition: color 0.15s;
            color: #333;
            &:hover {
                color: $snMainColor;
            }
        }
        i {
            width: 26px;
            font-size: 18px;
        }
    }
    .header__popup-login {
        label {
            margin-top: 20px;
        }
        input {
            width: 100%;
            height: 43px !important;
        }
    }
    .popup-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #00000050;
        z-index: 9999;
    }
    .account__actions {
        border-top: 1px solid #ddd;
        padding-top: 10px;
        margin-top: 20px;
        padding-left: 0;
        list-style: none;
    }
}
 .header__popup-hidden {
     display: none;
 }
.header__account {
    position: relative;
}

.header__button-submit {
    width: 100% !important;
    height: 43px;
    background: #852b2b;
    color: #fff;
    padding: 0 12px;
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background: $snBlue;
        color: #fff;
    }
}

.header__popup-actions button {
    width: 100%;
    margin: 10px 0 0 0;
}

.header__cart-second{
    margin-right: -50px!important;
}

@media (max-width: 1024px) {
    .header__row-middle {
        padding: 10px;
        .hmenu {
            display: flex !important;
        }
    }
    .handel__logo,
    .site-trust {
        display: none !important;
    }
    .header__phone {
        display: none;
    }
    .custom--trustpilot-desktop {display: none;}
}
@media (max-width: 768px) {
    .header__row-middle {
        margin-bottom: 0;
    }
}

// hide search field
// show search mobile rounded button
@media (max-width: 680px) {
    h1, h2 {
        font-size: xx-large !important;
    }
    .header__row-middle {
        .header__row-middle-right {
            .mobilesearch {
                display: flex;
            }
            .header__item-rounded {
                background: #dedede;
                color: #333;
            }
        }
    }
    .header__popup {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        max-width: 500px;
        margin: auto;
        padding: 36px;
        h5 {
            font-size: 20px;
        }
        .close {
            position: absolute;
            top: 12px;
            left: 12px;
            i {
                margin: 0;
            }
        }
        -webkit-box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (max-width: 500px) {
    h1, h2 {
        font-size: x-large !important;
    }
    .header__row-middle {
        height: 80px;
        .header__logo img {
            height: 60px;
        }
        .header__item-rounded {
            width: 40px;
            height: 40px;
            font-size: 20px;
        }
        .hmenu {
            width: 35px !important;
        }
    }
}

// hide phone number
@media screen and (max-width: 400px) {
    .header__item {
        margin-left: 12px;
    }
    .header__item-rounded {
        width: 35px;
        height: 35px;
        font-size: 18px;
    }
    .hmenu {
        width: 35px !important;
    }
}
