.page-header {
	padding: 0;
	margin: 0;
	border: 0
}
.hbox__module[class*="col-"]:first-child,
.bottom__module[class*="col-"]:first-child { padding-left: 0 }

.hbox__module[class*="col-"]:first-child,
.bottom__module[class*="col-"]:first-child { padding-right: 0 }

.row {margin: 0}

.btn {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
}
.pagination>li>a,
.pagination>li>span { color: $snBlue }
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
	background-color: $snBlue;
	border-color: $snBlue;
}
.modal-content {
	background: #f2f2f2;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
}
.modal-backdrop.in {
  opacity: .2;
  filter: alpha(opacity=20);
}
.modal-title {
	color: #000;
	padding: 20px 30px;
	font-size: 22px;
}
.modal-header { border: 0;}
.modal-header .close {
	color: #505050;
	opacity: 1;
	filter: alpha(opacity=100);
	font: normal 300 16px/1 'Open Sans', Arial, serif;
	height: 40px;
	margin-top: 15px;
	padding-right: 20px;
	span {
		display: inline!important;
		margin-left: 10px;
		font-size: 20px;
		overflow: hidden;
		padding: 0 8px;
		border: 1px solid #505050;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		border-radius: 50%;
	}
}
.close:focus, .close:hover {
	background: transparent;
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.has-error .checkbox,.has-error .checkbox-inline,.has-error .control-label,.has-error .help-block,.has-error .radio,.has-error .radio-inline,.has-error.checkbox label,.has-error.checkbox-inline label,.has-error.radio label,.has-error.radio-inline label {
    color: #a94442
}
.has-error {
	color: #a94442
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
}
.tos_label:hover { cursor: pointer }
.has-error .form-control:focus {
	background: #f0f0f0;
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ce8483
}
label.has-error {
	border: 1px solid #843534;
	font-size: 85%;
	margin: 10px 0!important;
	padding: 8px 5px;
	width: 100%;
	text-align: center;
}
.form-horizontal .control-label {
	text-align: left;
}
input[type="tel"]{
	background: #fff;
    border: 1px solid #cdcdcd;
    color: #111;
    font-size: 13px;
    line-height: 1.2;
    height: 33px!important;
    padding: 6px 15px!important;
    max-width: 100%!important
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }
.fa-cc-visa { color: #0E4595 }
.fa-cc-mastercard { color: #019CDE }
.visa__logo,
.mastercard__logo {
	background: url('../img/payment_icons/visa-curved-32px.png');
	display: inline-block;
	height: 32px;
	width: 51px;
	text-indent: 60px;
	line-height: 32px;
}
.mastercard__logo { background: url('../img/payment_icons/mastercard-curved-32px.png'); }
input[readonly],
input[readonly="readonly"]{
	background: #F6F6F6!important;
	color: #A3A3A3!important;
	cursor: not-allowed;
}
.modal-dialog.large {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	.modal-content { background: #060606; height: 100%}
	.modal-header .close { margin-top: -15px; color: #ddd }
	h4 { display: none }
	iframe { width: 100%; height: 95% }
	.modal-body { height: 100% }
}
.modal-dialog.medium {
	width: 800px;
	.modal-body {
		border: 1px solid #ccc;
		margin:  0 20px 20px;
		padding: 20px
	}
	.modal-header { padding: 15px 0 5px}
	.modal-header .close { margin-top: 0 }
	.modal-content {
	  height: 99%;
	  background: #fff;
	}
}
@media screen and (max-width: 800px){
	.modal-dialog.medium  { max-width: 95% }
}
body.modal-open {
    overflow: hidden!important;
    padding-right: 0 !important;
}
::-moz-selection {
	background-color: #373B48;
	color: #ffffff;
}

::selection {
	background-color: #373B48;
	color: #ffffff;
}
input[type="text"].loading {
	background: url('../img/ring-alt.gif') no-repeat no-repeat right 0;
}
