.top-banner {
    position: fixed;
    top: 0;
    width: 100%;
    color: #fff;
    z-index: 999;
    box-shadow: 0px 1px 5px 0px #00000060;
    p:last-of-type {
        margin: 0
    }
}
