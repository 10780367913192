body.lt-LT,
body.lv-LV,
body.et-EE {
	.mastercard__logo { display: none }
	.visa__logo {
		background: url('../img/payment_icons/paysera_logo.png');
		width: 133px;
		height: 28px;
	}
	.panel_cookie p {
		font-size: 13px;
		line-height: 17px;
		margin: 6px 0;
	}
	.productview__gallery--overlay .special__price,
	.productview__price strong { margin-left: 10px;}
	div.tresc { margin-left: 6% }
}
// LT
body.lt-LT {
	.contact-form { background: url('../img/contact_background-lt.jpg'); }
}
// LV
body.lv-LV {
	.contact-form { background: url('../img/contact_background-lv.jpg'); }
}
// EE
body.et-EE {
	.contact-form { background: url('../img/contact_background-ee.jpg'); }
}


@media screen and (max-width: $mediumDesktop) {
	body.lt-LT,
	body.lv-LV,
	body.et-EE {
		div.tresc {
			margin: 0 0 0 2%;
    		max-width: 82%;
	    	width: 82%;
		}
		.panel_cookie p {
			font-size: 12px;
			line-height: 16px;
		}
	}

}

@media screen and (max-width: $smallDevice) {
	body.lt-LT,
	body.lv-LV,
	body.et-EE {
		.panel_cookie p {
			font-size: 11px;
			line-height: 14px;
		}
	}
}
