body.pl-PL {
	.checkout__logos {
		padding-top: 15px;
		p { text-align: right }
	}
	.mastercard__logo { display: none }
	.visa__logo {
		background: url('../img/payment_icons/payu.png');
        width: 60px;
        height: 30px;
        margin-top: 0px;
		&.payu_installments{
			background: url('../img/payment_icons/payu_installments.png');
            width: 91px;
		}
	}
	.payment > .control-group { overflow: visible }
	.guarantee > span { display: block; margin-bottom: 3px; }
	.handel__logo { display: none }
	.contact-form {
		background: url('../img/contact_background-pl.jpg');
	}
	.notifyme__form #jform_tos_consent {
		vertical-align: top;
		margin-top: 0;
	}
	.notifyme__form #jform_tos_consent-lbl {
		max-width: 95%;
	}
	.basket__table input[type=checkbox]+label {
		font-size: 14px;
		line-height: 18px;
	}
	.basket__table .tos_label a { display: block;}
	.basket__table input[type=checkbox]+label:before {
		height: 100px;
	}
	.copyright__text { font-size: 80%; margin-bottom: 0;}
}
body.pl-PL.view-basket,
body.pl-PL.view-checkout {
	.guarantees {
		margin-top: 0;
		padding: 0;
	}
	.guarantee { padding-right: 10px }
}
body.isFrontpage.pl-PL {
	.bottom h2 { font-size: 24px }
}
@media (min-width: 968px){
	body.pl-PL .contact-address.dl-horizontal dt { width: 170px }
	body.pl-PL .contact-address.dl-horizontal dd { margin-left: 190px }
}
@media (max-width: 968px){

	body.pl-PL .contact-address.dl-horizontal dt { width: 100%; text-align: left; margin-bottom: 10px; }
	body.pl-PL .contact-address.dl-horizontal dd { margin-left: 0}
}