.input-hidden { display: none !important }
#basket__form {
	position: relative;
}

h3.carousel{
    margin: 0 0 10px 10px;
}

.basket__top {
	.moduletable {
		border: 2px solid #d7d7d7;
		padding: 10px 15px;
	}
	p { margin: 0}
}
.checkout__steps {
	clear: right;
	margin: 0 auto;
	counter-reset: step;
	padding-left: 0;
	overflow: hidden;
}
.checkout__step {
	list-style-type: none;
	width: 33%;
	float: left;
	font-size: 12px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	color: #585858;

	span { color: #585858 }
}

.checkout__step:after {
	 width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #ccc;
      top: 15px;
      left: -50%;
      z-index: -1;
}
.checkout__step:before {
	width: 30px;
	height: 30px;
	content: counter(step);
	counter-increment: step;
	line-height: 30px;
	border: 2px solid #ccc;
	display: block;
	text-align: center;
	margin: 0 auto 10px auto;
	border-radius: 50%;
	background-color: white;
}
.checkout__step:first-child:after {
	content: none;
}

.checkout__step.checkout__step--active + li:after,
.checkout__step.checkout__step--visited + li:after {
	background-color: #00AD75;
}
.checkout__step.checkout__step--active,
.checkout__step.checkout__step--visited {
	color: #00AD75;
}
.checkout__step.checkout__step--active:before,
.checkout__step.checkout__step--visited:before {
	border-color: #00AD75;
}
.checkout__step.checkout__step--visited:before {
	background: #00AD75;
	color: #fff;
	content: "\f00c";
	font: normal normal normal 14px/26px FontAwesome;
}
.checkout__step.checkout__step--active span {
	font-weight: bold;
	color: #00AD75;
}
.checkout__step.checkout__step--active:before {
	border-color: #00AD75;
	content: "\f111";
	font: normal normal normal 14px/26px FontAwesome;
}
#promocode__form {
	width: 50%;
}
#basketSummary { white-space: nowrap; }
.basket__table {
	color: #585858;
	clear: both;
	font: normal 400 15px/1 'Noto Serif', Arial, serif;
	margin-top: 30px;
	width: 100%;

	thead th {
		padding: 15px 5px;
		font-weight: 400;
	}
	tbody td,
	tfoot td {
		border: 1px solid #cbcbcb;
		padding: 20px;
	}
	tr.success td { background: #cee9e0; }
	tfoot { border: 2px solid #535353 }
	.product__desc {
		padding: 0;
	}
	.basket__empty {
		background: #fcf9eb;
		font-style: italic;
		text-align: center;

		i {
			font-size: 40px;
			display: block;
			padding: 20px 0;
			color: #ccc;
		}
	}
	.product__image {
		padding-left: 0;
		img { width: 100% }
	}
	.product__usp {
		font: normal 400 14px/1.1 'Open Sans', Arial, serif;
		margin-bottom: 0;
	}
	.product__name { color: #343434; line-height: 1.2em; }
	.basket__price,
	.basket__total { text-align: right; white-space: nowrap }
	.basket__total--strike { text-decoration: line-through;}
	.promocode__text {
		font: normal 400 17px/1.1 'Open Sans',Arial,serif;
	}
	.basket__quantity div {
		display: block;
		margin: 0 auto;
		width: 60px;
		position: relative;
		> input { background: #fff!important; color: #111!important; padding: 2px; border: 1px solid #fff; height: 32px; cursor: default; pointer-events: none; }
	}
	.product__regulations {
		font: normal 700 14px/1.1 'Open Sans', Arial, serif;
		margin: 7px 0 0;
		display: block;
		text-decoration: underline;
		i {margin-right: 5px}
	}
	.basket__quantity input { width: 30px; text-align: center; }
	.basket__price,
	.basket__quantity,
	.basket__total { font-size: 19px}
	.quanity_inc,
	.quanity_dec {
		color: #cacaca;
		display: block;
		float: right;
		font-size: 10px;
		text-align: center;
		height: 15px;
		width: 25px;
		border: 1px solid #cacaca;
		@include transition();

		&:hover { background: #cacaca; color: #fff}
	}
	.quanity_dec { margin-top: -15px }
	.product_remove {
		color: #ddd;
		font-size: 15px;
		position: absolute;
		padding: 3px 4px;
		right: -25px;
		top: 5px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		border-radius: 50%;
		i { margin-left: 1px }
		&:hover {
			background: #ddd;
			color: #fff;
		}
	}
	.basket__shipping {
		font-size: 80%;
		font-family: 'Open Sans', Arial, serif;
		font-style: italic;
		text-align: right;
		color: #777;
	}
	.basket_list {
		list-style-type:none;
		padding:0px;
		line-height: 2em;
		li {
			padding: 0px;
		}
	}
}

.basket_list--vat, .vatRate {
	font-size:  80%;
	li {
		line-height: 1.5em;
	}
}

.view-basket .alert-heading { display: none }
.cart__regulations {
	border: 1px solid #abaaaa;
	background: #fff;
	max-height: 450px;
	overflow-y: scroll;
	padding: 15px;
}
.modal-body {
	padding: 0 40px 40px;
	font: normal 400 14px/1.3 'Open Sans', Arial, serif;
}
.basket__table.rules {margin-top: 0px}
.basket__table.rules td { border: 0;}
.basket__table .promocode {
	padding: 0;
	input { width: 100%!important; height: 39px!important }
}
label.tos_label.additional_tos {
	line-height: 17px !important;
	margin-left: -10px;
	margin-top: 5px;
}
.tos_label { margin: 0!important }
.basket__rules { width: 100%; margin-bottom: 15px; padding: 5px}
.basket__recurring_payment{ width: 100%; margin-bottom: 15px; margin-left: -5px; margin-top: 5px; padding: 5px 0px 5px 5px;}
.basket__table.rules td.promocode__heading { padding: 0 }
.basket__table.rules td.promocode { vertical-align: top }
input[name="promocode"] { height: 39px !important; border-color: #b3b2b2 }
.grey {
	background: #F6F6F6;
	border: 1px solid #CCCCCD;
	color: #565656;
	padding: 9px 30px;
	margin-left: -1px;
	&:hover {
		background: $snMainColor;
		border-color: $snMainColor;
		color: #fff;
	}
}
body.fr-FR, body.nl-NL, body.nl-BE{
	.grey {
		color: #fff;
	}
}
.setpass, .magic{
	width:39%;
}
.magic{
	margin-left:8px;
}
#form-login-username label.has-error{
	width:80%;
}
#checkout,
.checkout {
	font-size: 16px;
	padding: 10px 20px;
	float: right;
}
.checkout {
	padding: 15px;
	font-size: 18px;
}
#checkout:after,
.checkout:after {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
	content: "\f054";
	padding: 0 5px 0 20px;
}
.basket a[rel=parent] {
	color: #111;
	font: italic 300 15px/1.3 'Noto Serif',Arial,serif;
	margin: 20px 0 10px;
	padding: 10px 15px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	float: left;
	i { margin-right: 25px }
	&:hover { background: #e3e3e3 }
}
#login-form .btn-primary {
	width: 80%;
}
#member-registration .btn-primary {
	width: 98%;
	padding: 12px 15px;
}
.basket {
	.productview__cart {
		font-size: 15px;
		padding: 8px 12px;
		width: auto;
		right: 0;
	}
	.promoproduct__price {
		font-size: 17px;
		left: 15px !important;
		position: relative;
		padding: 15px 0 0;
		display: block;
		position: absolute;
		left: 15px;
		padding-bottom: 0 !important;
		width: 50% !important;
		bottom: 1px;
		text-align: left !important;
		&.noDiscount{
			bottom: 11px;
		}
		.crossed{
			position: absolute;
			top: -4px;
			left: 0;
			padding: 0;
		}
	}
	legend {
		font: normal 600 17px/1.3 'Noto Serif',Arial,serif;
		border-bottom: 0;
		margin-bottom: 10px;
	}
	input::placeholder {
		line-height: normal;
	}
	input[type=email], input[type=password], input[type=text], input[type=url], input[type=tel], select, textarea, #jform_snprofile_county_chzn {
		height: 39px!important;
		font-size: 14px;
		width: 80%;
		display: block;
	}
	#jform_snprofile_county_chzn input { width: 100%!important }
	.controls {
		margin-bottom: 5px;
		.grey,
		.btn-primary {
			padding: 12px 35px;
		}
	}
	.account__actions {
		border: 0;
		margin-top: 0;
	}

	#form-login-remember { margin: 10px 0}
	ul.dl-horizontal {
		list-style: none;
		padding: 20px 40px 20px 0;

		span { width: 120px; font-weight: bold; margin-right: 15px; display: inline-block}
		li { padding: 7px 0; border-top: 1px solid #DFDFDF}
		li:last-child { border-bottom: 1px solid #DFDFDF }
	}
}
.basket__bottom {
	background: #f5f5f5; padding: 35px 0 50px;
	h3 {
		border-bottom: 1px solid #c4c4c4;
		color: #545454;
		font: normal 400 17px/1.3 'Noto Serif',Arial,serif;
		text-transform: none;
		padding-bottom: 10px;
	}
}

#member-registration {
	.has-error { max-width: 100% }
	fieldset + fieldset { margin-top: 5px}
	.form-horizontal .radio  {margin-top: 0!important }
	.radio input[type=radio] { position: relative; margin-left: 3px}
	.radio label { display: inline-block; margin-right: 20px;}
	.control-group {
		width: 100%;
		clear: both;
		display: block;
		overflow: hidden;

		input[type="text"],
		input[type="tel"],
		input[type="email"],
		input[type="password"],
		#crafty_postcode_lookup_result_option1 { width: 97%!important; }
		.control-label { width: 30%; display: inline-block; float: left}
		.controls { width: 70%; display: inline-block; float: left }
		#crafty_postcode_lookup_result_option1 {
			border-radius: 0;
			margin-top: 10px;
  			-webkit-border-radius: 0px;
		}
		.lookup { position: relative; width: 100%; }
		#jform_snprofile_email_consent label.has-error { text-align: center }
		#jform_snprofile_email_consent-lbl { line-height: 1.4em; margin-bottom: 10px }
	}
	.btn-group-yesno { overflow: hidden; width: 98%!important; padding: 0 }
	.switch-title { margin-bottom: 6px }
	.btn-group-yesno input { z-index: 10; opacity: 0; position: absolute!important }
	.btn-group-yesno label { float: left }
	.btn-group-yesno label:not(.has-error) {
	  display: inline-block;
	  z-index: 20;
	  width: 50%;
	  background-color: #f0f0f0;
	  color: rgba(0, 0, 0, 0.6);
	  font-size: 14px;
	  font-weight: normal;
	  text-align: center;
	  text-shadow: none;
	  padding: 6px 14px;
	  margin: 0;
	  border: 1px solid rgba(0, 0, 0, 0.2);
	  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1);
	  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1);
	  -webkit-transition: all 0.1s ease-in-out;
	  -moz-transition:    all 0.1s ease-in-out;
	  -ms-transition:     all 0.1s ease-in-out;
	  -o-transition:      all 0.1s ease-in-out;
	  transition:         all 0.1s ease-in-out;
	}
	.btn-group-yesno label.has-error { width: 100%; line-height: 1.2em; padding: 5px; }
	.btn-group-yesno label:hover { cursor: pointer }
	.btn-group-yesno input:checked + label {
		background-color: #92D057;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.btn-group-yesno input[type="radio"] {
		left: 0px;
	}
}

#jform_snprofile_county_chzn {
	width: 97%!important;
	> a {
		background: #fff!important;
		border-color: #cdcdcd!important;
		height: 39px;
		line-height: 39px;
		border-radius: 0;
		padding-left: 15px;
		box-shadow: none;
	}
	.chzn-single div b {
		background-position-y: 10px;
	}
}
body.ie-IE #member-profile #jform_snprofile_county_chzn { width: 80%!important }
body.ie-IE #member-registration .control-group,
body.ie-IE #member-profile .control-group { overflow: visible }
footer { margin: 0 0 30px }
.checkout__row {
	clear: both;
	display: block;
	padding-top: 30px;

	> div:first-child { padding-left: 0; padding-right: 0; width: 47% }
	> div:last-child { padding-left: 60px; border-left: 1px solid #B0B0B0 }
}
.checkout__basket--small {
	background: #f7f7f7;
	border: 1px solid #ddd;
	margin: 50px 40px 20px 0;
	overflow: hidden;
	padding: 20px;
	position: relative;

	ul { list-style: none; overflow: hidden; padding: 0; position: relative; z-index: 2;}
	li { border-bottom: 1px solid #AEAEAE; overflow: hidden; width: 100%; padding: 10px 5px; }
	p { width: 80%; float: left; margin: 0}
	span {width: 20%; float: left; text-align: right}
	.basket__item--total { font-weight: bold; background: #F5F5F5}
}

#member-profile { display: none }
#shipping-payments fieldset + fieldset { margin-top: 0}
.radio.inline {
	display: inline-block;
	width: 130px;
	input[type=radio] { margin-top: 10px!important}
}
#shipping-payments .shipping__type { margin-top: 25px }
#shipping-payments svg { margin-top: -6px }
.vippsIcon,
.vippsText {
    vertical-align: middle;
    display: inline-block;
}
.vippsText {
    margin-left: 7px;
    margin-top: -8px;
}
select.pickuppoint { width: 100%; }
.well .control-group--pickuppoints {  margin-top: 15px; display: none }

.view-checkout {
	input[type=radio] { margin-top: 4px!important }
	label.has-error {
		width: 97%;
		border: 1px solid #843534;
		margin: 10px 0 0!important;
		padding: 5px;
	}
	#member-profile label.has-error {
		width: 80%;
		border: 1px solid #843534;
		font-size: 85%;
		margin: 10px 0 !important;
		padding: 8px 5px;
	}
	.payment_icons {
		display: inline-block;
		margin: -7px 0 0 15px;
		position: absolute;
		width: max-content;
		span { margin-right: 3px }
	}
}
.view-thankyou {
	.basket h3 {
		font: normal 400 21px/1.4 'Noto Serif',Arial,serif
	}
	.checkout__row ul {
		list-style: none;
		padding: 20px 0 20px 10px;
	}
	td.col-md-3 {
		text-align: right;
		white-space: nowrap;
	}
	tfoot {
		background: #f4f4f4;
		font-family: 'Noto Serif',Arial,serif;

		td {
			border-top: 1px solid #737373!important;
			border-bottom: 1px solid #737373;
		}
	}
	.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
		padding: 12px 8px;
	}
	.checkout__row>div:last-child { border: 0 }
	.upsell {
		background: #1c2f39;
		color: #fff;
		padding: 15px 20px 70px;
		text-align: center;
		position: relative;
	}
	.upsell.transparent {
		background: transparent;
		color: #111;
		text-align: center;
	}
	.upsell__button {
		color: #fff;
		position: absolute;
		right: 15px;
		bottom: 0;
		a {
		  	background: #00AD75;
		  	padding-left: 20px;
			color: #fff;
			position: absolute;
			bottom: 0;
			right: -15px;
			white-space: nowrap;
		    @include transition();
		  	&:hover {
				background: #10391d
			}
		}
	}
	.upsell__img {
		width: 100%;
	}
	.upsell__price {
		float: left;
		font-size: 22px;
		line-height: 70px;
		text-indent: 10px;
	}
	.upsell__usp { font-size: 18px }
}
.postage__logos {
	background: url('../img/no/postage_logos.png');
	display: inline-block;
	float: left;
	height: 63px;
	width: 110px;
	margin-top: -5px;
}
.basket__summary {
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	margin: 20px 0 10px;
	padding: 10px 0;
    .vatRate{
        font-size: 90%;
    }
	td {
        padding-top: 10px; padding-bottom: 10px; font: normal 400 16px/1.3 'Noto Serif',Arial,serif;

    }
}
.payment__options {
	float: right;
	padding-right: 0
}
.payment__online { margin-top: 10px;}
.payment__row {
	display: block;
	width: 100%;
	clear: both;
	margin-top: 15px;
	overflow: hidden;
	input[type=radio] { margin-left: 0!important }
	label { font-size: 90%; margin-bottom: 10px }
 	label img {
 		display: block;
 		max-width: 120px;
 	}
}
.view-checkout .handel__wrapper { margin-top: 0 }
.checkout__logos {
	clear: both;
	display: block;
	padding-top: 60px;
	overflow: visible;

	img { margin: 0 0 0 20px; float: right}
}
input.success {
	background: #fff!important;
	border: 1px solid #cdcdcd!important;
}

/*input.success {
	border: 1px solid #78C610;
}*/
.basket__table.rules .row {
	padding: 15px 0;
}
.error {
	background: #f2dede;
	color: #111;
}
.guarantees { margin-top: 10px; float: right }
.guarantee {
	font-size: 85%;
	padding-left: 0;
}
.guarantee__icon {
	float: left;
	height: auto;
	padding: 0 10px 0 0;
	width: 40%;
}


/* Calendar */
/**
 * @copyright	Copyright (C) 2005 - 2017 Open Source Matters, Inc. All rights reserved.
 * @license		GNU General Public License version 2 or later; see LICENSE.txt
 */
 .js-calendar {
	box-shadow: 0 0 15px 4px rgba(0,0,0,.15) !important;
 }
.calendar-container {
	float: left;
	min-width: 160px;
	padding: 0;
	list-style: none;
	border-radius: 5px;
	background-color: #ffffff !important;
	z-index: 1100 !important;
}
.calendar-container table {
	table-layout: fixed;
	max-width: 262px;
	border-radius: 5px;
	background-color: #ffffff !important;
	z-index: 1100 !important;
}
/* The main calendar widget.  DIV containing a table. */
div.calendar-container table th, .calendar-container table td {
	padding: 8px 0;
	line-height: 1.1em;
	text-align: center;
}

div.calendar-container table body td {
	line-height: 2em;
}

div.calendar-container table td.title { /* This holds the current "month, year" */
	vertical-align: middle;
	text-align: center;
}

.calendar-container table thead td.headrow { /* Row <TR> containing navigation buttons */
	background: #fff;
	color: #000;
}

.calendar-container table thead td.name { /* Cells <TD> containing the day names */
	border-bottom: 1px solid #fff;
	text-align: center;
	color: #000;
}

.calendar-container table thead td.weekend { /* How a weekend day name shows in header */
	color: #999;
}

/* The body part -- contains all the days in month. */

.calendar-container table tbody td.day { /* Cells <TD> containing month days dates */
	text-align: right;
}

.calendar-container table tbody td.wn {
	background: #fff;
}

.calendar-container table tbody td.weekend { /* Cells showing weekend days */
	color: #999;
}

.calendar-container table tbody td.hilite { /* Hovered cells <TD> */
	background: #999999;
	color: #ffffff;
}

.calendar-container table tbody td.day {
	border: 0;
	cursor : pointer;
	font-size: 12px;
}

.calendar-container table tbody td.day.wn {
	text-align: center;
	background-color: #f4f4f4;
}

.calendar-container table tbody td.selected { /* Cell showing today date */
	background: #3071a9;
	color: #fff;
	border: 0;
}

.calendar-container table tbody td.today {
	position: relative;
	height: 100%;
	width: 100%;
	font-weight: bold;
}
.calendar-container table tbody td.today:after {
	position: absolute;
	bottom: 3px;
	left: 3px;
	right: 3px;
	content: "";
	height: 3px;
	border-radius: 1.5px;
	background-color: #46a546;
}
.calendar-container .btn {
	margin-right: 4px;
	padding: 5px 10px;
	font-style: 90%;
}
.calendar-container table tbody td.today.selected:after {
	background-color: #fff;
}

.calendar-container table tbody td.day:hover {
	cursor: pointer;
	background: #3d8fd7;
	color: #fff;
}
.calendar-container table tbody td.day:hover:after {
	background-color: #fff;
}

.calendar-container table tbody .disabled {
	color: #999;
	background-color: #fafafa;
}

.calendar-container table tbody .emptycell { /* Empty cells (the best is to hide them) */
	visibility: hidden;
}

.calendar-container table tbody .emptyrow { /* Empty row (some months need less than 6 rows) */
	display: none;
}
.buttons-wrapper {
	padding: 5px 5px;
}
a.js-btn.btn.btn-exit, a.js-btn.btn.btn-today, a.js-btn.btn.btn-clear {
	cursor: pointer;
	text-decoration: none;
	min-width: 60px;
}
.calendar-container .calendar-head-row td {
    padding: 4px 0 !important;
}
.calendar-container .day-name {
	font-size: 0.7rem;
	font-weight: bold;
}
.calendar-container .time td {
	padding: 8px 0 8px 8px;
}
.time .time-title {
	background-image: url("data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAdlJREFUeNqklUsohFEUx7/5kgWGjEcRJULJSmJhITQLr8UoFko2k2SDLGZD2SglJcXOxo6Fx0ayUt4jWXgNG2rIwsgMJZPn/+o/dRvfa8yp33zfPfecM+e795x7bYqxtIAaUA2KgA3cgn3gBcsgrMQg3eAAfJtwBYa0Atg0dAugg+/3YAUcgkvqCkAVcIIy6raBCwT0/mRdymYG5Bt8VQoYBe+0Pwdpik6mwuAVNMawbJXgmr7e6FVokzKt1XBOZnYuneB54Jn+w/LEMZXTOo65nN80yNxNmyBIF4o6Ku6AXccpmzbzJstyQbs+FT/1VC6CFyU+mePTqbIBhBwp8csGn8UqO0qIz8AhstNBk8BP4As4RGCVyk8LgUVVlFvIXFXZ+4qUuZY8gn6W1QmYBKkadklM9PfLJriTYxYyEYFXpZp3s8Yj0kv9mhi0cuCTlsVMuqRWbpL0Xuo8YpAotaQnhgoQSzEAMjhuZoywfMb0UCmyKP1HmSXzJBQxpqIn9zhxA0piCJoFdqXu/bOpmeCMBiF+ZoJJ0E7gp0/AKCF71M1xCkZ4NRWSCjAIdiQ7v9UlHAcPUdfQh1QJEd7ArOg0K1dTRHJAO2hg8zhoH2IVbYElvaPgR4ABAFM/gtHnpJfxAAAAAElFTkSuQmCC");
	background-repeat: no-repeat;
	background-position: center;
}
.controls--invalid {
	border: 1px solid #ff5959;
    background-color: rgba(255,89,89,.02);
    box-shadow: 0 0 3px 0 rgba(255,89,89,.3);
    padding-right: 40px;
    border-radius: 3px;
    position: relative;
    width: 100%;

    label.has-error {
		border: 0;
		background: none!important;
		width: 100%;
		margin: 0!important;
		padding: 0;
		text-align: left;
    	display: block;
	    color: #ff5959!important;
	    font-weight: 400;
	    font-style: italic;
	    font-size: 13px;
	    line-height: 1.7;
    }
}
.controls--invalid:before {
	color: #ff5959;
    content: "\f071";
    display: none;
    font: 18px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: 20px;
    top: 20px;
}
label.has-warning {
    color: #856404;
    background-color: #fff3cd;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 3px;
    width: auto;
}
.row-layout {
    display: grid;
    grid-template-areas:"thanks upsell"
                        "carousel carousel";
    grid-template-columns: 5fr 4fr;
}

.row-layout .upsell legend {
    margin-top: 30px;
}

.row-layout  > .thanks {
    grid-area: thanks;
    padding: 10px;
}

.row-layout  > .upsell {
    grid-area: upsell;
}

.row-layout  > .address {
    background: transparent;
    text-align: left;
    color: black;
    padding: 55px 10px 10px 50px;
}

.row-layout  > .carousel {
    grid-area: carousel;
}

@media (max-width: 768px) {
    .row-layout  {
        grid-template-areas:"thanks thanks"
                            "carousel carousel"
                            "upsell upsell";
    }
    .row-layout  > .carousel {
        margin: 0 0 10px 0;
    }
}
@media (min-width: 550px) and (max-width: 990px) {
	.basket__table{
		.basket__desc {
			.product__image {
				width: 33.33333%;
				float: left;
			}
			.product__desc {
				width: 66.66667%;
				float: left;
			}
		}
	}
}
@media (max-width: 576px) {
    .thanks{
        h4{
            font-size: 15px;
        }
    }
}
@media (max-width: 374px) {
	.basket__table{
		.product__image{
			img{
				display: none !important;
			}
		}
	}
}
@media (min-width: 561px) and (max-width: 991px) {
	.basket__table{
		.product__image{
			max-height: 19vw;
			width: auto;
			margin: 0 auto;
			display: block;
			img{
				height: auto !important;
				width: auto;
				margin: 0 auto;
				display: block;
				max-height: 19vw;
			}
		}
	}
}
@media (min-width: 992px) and (max-width: 1140px) {
	.basket__table{
		.product__image{
			max-height: 80px;
			img{
				height: auto !important;
				width: auto;
				margin: 0 auto;
				display: block;
				max-height: 7.02vw;
			}
		}
	}
}
@media (min-width: 1141px) {
	.basket__table{
		.product__image{
			max-height: 80px;
			img{
				height: auto !important;
				width: auto;
				margin: 0 auto;
				display: block;
				max-height: 80px;
			}

		}
	}
}

@import 'togglePasswordVisibility.scss';

.basket-cta-row {
	display: flex!important;
	justify-content: flex-end;
	width: 100%;
	align-items: center;

}
