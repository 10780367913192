.search{
	overflow-y: hidden;
}
.view-search .main {
	padding-bottom: 0;
}
.search__wrapper {
    * {
        box-sizing: border-box;
    }
    .main__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

    }
    i {
        flex-basis: calc(33.33% - 15px);
        margin-bottom: 40px;
        flex-direction: column;
        align-items: center;
    }
    .search__result {
        flex-basis: calc(33.33% - 15px);
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;


    }
    .prouduct__image {
        background: #f5f5f5;
        padding: 50px 15px;
        margin-bottom: 12px;
    }
    .badges {
        position: absolute;
        left: 10px;
        top: 10px;
        span {
            font-size: 10px;
            display: inline-block;
            background: #fff;
        }
        .category {
            border: 1px solid #ccc;
        }
    }
    .product__title,
    .search__details--item{
        color: #111;
        font-size: 16px;
        font-weight: bold;
    }
    .search__details--item {
        color: #852b2b;
    }
    .product__desc,
    .productprice__price--crossed{
        font-size: 15px;
        color: #757575;
    }
    .notify_me_btn {
        width: fit-content;
        border: none;
        border-radius: 1px;
        background: #852b2b;
        border: 0;
        color: #fff;
        font: normal 400 14px/1.4 'Open Sans', Arial, serif;
        padding: 10px 15px!important;
    }
}
#searchForm {
	.searchbar {
		box-shadow: 0px 1px 6px rgba(0,0,0,0.2);
	}
	.search__icon {
		color: #ddd;
		display: block;
		margin: 0 auto;
		text-align: center;
		padding: 20px 0 0;
	}
	.search__filters {
    	margin: 15px 0 25px;
        display: flex;
        flex-wrap: wrap;

        strong {
            margin-top: 5px;
            display: block;
        }
        div {
            flex: auto;
            margin: 0 10px;
            box-sizing: border-box;
        }
        div:last-child {text-align: right; }
		label,
        input[type="checkbox"], .checkbox, input[type="radio"], .radio{
			display: inline-block;
			margin-right: 20px;
            margin-top: 4px!important;
            margin-bottom: 5px;
		}
        .radio + .radio, .checkbox + .checkbox {
            margin-top: 5px;
        }
	}

	#search-searchword {
		display: inline-block;
		height: 45px!important;
		line-height: 45px;
		font-size: 15px;
		width: calc(100% - 100px);
	}
	#searchBtn {
		display: inline-block;
		float: right;
		width: 100px;
		height: 45px !important;
	}
}

.search {
	.error {
		background: #e47f3c;
		margin: 40px 0 60px;
		padding: 20px;
		font-size: 140%;
		text-align: center;
		color: #fff;
	}
}
@media screen and (max-width: 768px) {
    .search__wrapper .search__result {
        flex-basis: calc(50% - 15px);
    }
    .controls--only,
    .controls--only + .controls {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .search__wrapper .search__result {
        flex-basis: 100%;
    }
}
