body.uk-UK {
	.checkout__logos {
		padding-top: 15px;
		p { text-align: right }
	}
	#lookForAddress {
		position: absolute;
		right: 3%;
		top: 0;
		i { margin-right: 0 }
	}
	#member-profile #lookForAddress,
	#member-profiel #lookUpResults { display: none }
	#jform_snprofile_postal_code { text-transform: uppercase }
	.payment > .control-group { overflow: visible }
	.guarantee > span { display: block; margin-bottom: 3px; }
	.handel__logo { display: none }
	.contact-form {
		background: url('../img/contact_background-uk.jpg');
	}
	.notifyme__form #jform_tos_consent {
		vertical-align: top;
		margin-top: 0;
	}
	.notifyme__form #jform_tos_consent-lbl {
		max-width: 95%;
	}
	.basket__table input[type=checkbox]+label {
		font-size: 16px;
		line-height: 18px;
	}
	.copyright__text { font-size: 80%; margin-bottom: 0;}
}
body.uk-UK.view-basket,
body.uk-UK.view-checkout {
	.guarantees {
		margin-top: 0;
		padding: 0;
	}
	.guarantee { padding-right: 10px }
}
body.isFrontpage.uk-UK {
	.bottom h2 { font-size: 24px }
}
@media (min-width: 968px){
	body.uk-UK .contact-address.dl-horizontal dt { width: 170px }
	body.uk-UK .contact-address.dl-horizontal dd { margin-left: 190px }
}
@media (max-width: 968px){

	body.uk-UK .contact-address.dl-horizontal dt { width: 100%; text-align: left; margin-bottom: 10px; }
	body.uk-UK .contact-address.dl-horizontal dd { margin-left: 0}
}
