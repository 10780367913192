#mauticform_wrapper_lmocollect {
    margin-bottom: 30px;
    .mauticform-row {
        margin-bottom: 5px;
        label {
            width: 130px;
        }
        input {
            width: auto;
        }
        select {
            margin-left: 4px;
            width: 80px;
            height: 40px!important;
        }
    }
    .mauticform-errormsg {
        width: 100%;
        font-size: .75em;
        color: #dc3545;
        display: block;
    }
    .mauticform-button-wrapper {
        margin-top: 10px;
    }
    .mauticform-helpmessage {
        display: none;
    }
}