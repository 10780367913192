.promobox {
    padding: 0;
    z-index: 10;
    picture {
        padding: 0;
        border: 0;
        img {
            border: 0;
        }
    }
}
.promobox__rotator {
    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.promobox__figure {
    display: block;
    float: none;
    height: auto;
    margin: 0 !important;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    zoom: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    img {
        height: auto;
        width: 100%;
        &:hover {
            cursor: pointer;
        }
    }
    &:first-child {
        display: block;
        float: left;
        position: relative;
    }
}
.promobox__figure--active {
    float: left;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    position: absolute;
    z-index: 1;
}
.promobox__pagination {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 99;

    li {
        cursor: pointer;
        display: inline-block;
        margin: 0 5px 0 0;
        opacity: 0.9;
        -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
        @include transition(0.45s);
    }
}
.promobox__pagination.promobox__pagination--inner {
    bottom: 15px;
    position: absolute;
}
.promobox__pagination--active {
    color: #ddd;
}
.promobox__pagination--numbers {
    font-size: 1.1rem;
}
.promobox__pagination--dots li {
    background: $sngrey;
    height: 12px;
    text-indent: -9999px;
    width: 12px;
    border-radius: 6px;
}
.promobox__pagination--dots .promobox__pagination--active {
    background: $snMainColor;
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
}

.promobox__arrows {
    color: $sngrey;
    cursor: pointer;
    font-size: 6rem;
    margin: 0 2px;
    position: absolute;
    top: 50%;
    margin-top: -27px;
    width: 100%;
    z-index: 1;
    .promobox__arrow {
        opacity: 0.9;
        @include transition();
        i {
            font-size: 6rem;
        }
    }
    .promobox__arrow--prev {
        float: left;
        left: 50px;
        margin-left: 10px;
    }
    .promobox__arrow--next {
        float: right;
        right: 50px;
        margin-right: 10px;
    }

    .promobox__arrow--prev,
    .promobox__arrow--next {
        &:hover {
            color: $snMainColor;
        }
    }
}

.promobox__pagination--outer {
    padding-top: 10px;
}
