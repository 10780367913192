@media print {
 	dd.print-icon { display: none; }
	a[href]:after { content: none !important; }
	header {
		.site-search, 
		.minicart, 
		.mobilesearch,
		.header__inner > .header__row:first-of-type,
		.infobar .pull-right,
		.navigation,
		.pmbar { display: none!important; visibility: hidden!important; }
		.header__logo { margin-top: 15px; }
	}
	.navigation--mobile,
	.productview__navigation,
	.infobar,
	.productview__social,
	.row.recommended,
	.newsletter.container-fluid,
	.glogos.container-fluid,
	.productview__cart,
	#tmcs,
	#back-top,
	.nav.nav-tabs,
	footer,
	#topbar,
	.menu__dropline,
	.cookiebanner,
	.navigation, { display: none!important; visibility: hidden!important; }
	.description__prices { padding-top: 10px; }
	.productview__specification {
		border: 1px solid #fff;
		border-top: 1px solid #ccc;
		padding: 0;
		margin-top: 20px;
		h5 { margin: 20px 0 20px 0; }
		dl {
			width: 100%;
			overflow: hidden;
			padding: 0;
			margin: 0;
		}
		dt {
			width: 30%;
			float: left;
			padding: 5px 0;
			margin: 0;
		}
		dd {
			float: left;
			padding: 5px 0;
			margin: 0;
		}
	}
	.productview__actions { padding-bottom: 0!important; }
	body { padding-top: 10px!important; }

	// Landing Page 
	body.landing-page {
		aside { margin-top: 0; }
		.banner__inner img { width: 55%; margin: 30px auto; }
		.banner { min-height: auto; }
	}
	.tab-content {
		border: none!important;
	    padding: 40px 0 0 0!important;
	}
	.tab-content > .tab-pane {
		a { 
			text-decoration: underline;
			&[href^="http"]:after {
				content:" (" attr(href) ") "!important;
			}
		}
	    display: block !important;
	    opacity: 1 !important;
	    visibility: visible !important;
	    padding-bottom: 30px;
	    margin-bottom: 30px;
		border-bottom: 1px solid #ccc;
	    &:last-child {
			margin-bottom: 0px;
			border-bottom: none;
	    }
	}
	.productview__tabs { margin-top: 0!important; }
	.main { padding-bottom: 0; }
}
