body.ie-IE {
	.checkout__logos {
		padding-top: 15px;
		p { text-align: right }
	}
	.payment > .control-group { overflow: visible }
	.guarantee > span { display: block; margin-bottom: 3px; }
	.handel__logo { display: none }
	.contact-form {
		background: url('../img/contact_background-ie.jpg');
	}
	.notifyme__form #jform_tos_consent {
		vertical-align: top;
		margin-top: 0;
	}
	.notifyme__form #jform_tos_consent-lbl {
		max-width: 95%;
	}
	.basket__table input[type=checkbox]+label {
		font-size: 16px;
		line-height: 18px;
	}
	.basket__table input[type=checkbox]+label:before {
		height: 100px;
	}
	.copyright__text { font-size: 80%; margin-bottom: 0;}
}
body.ie-IE.view-basket,
body.ie-IE.view-checkout {
	.guarantees {
		margin-top: 0;
		padding: 0;
	}
	.guarantee { padding-right: 10px }
}
body.isFrontpage.ie-IE {
	.bottom h2 { font-size: 24px }
}
@media (min-width: 968px){
	body.ie-IE .contact-address.dl-horizontal dt { width: 170px }
	body.ie-IE .contact-address.dl-horizontal dd { margin-left: 190px }
}
@media (max-width: 968px){

	body.ie-IE .contact-address.dl-horizontal dt { width: 100%; text-align: left; margin-bottom: 10px; }
	body.ie-IE .contact-address.dl-horizontal dd { margin-left: 0}
}