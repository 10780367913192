/*--------------------------------------
--- Bottom mobile menu
--------------------------------------*/

@media screen and (max-width: 991px) {
	.stickyMobileBottomBar {
		position: fixed;
		bottom: -1px;
		left: 0;
		right: 0;
		padding: 15px;
		background: #fff;
		z-index: 9999;
		box-shadow: rgba(0,0,0,0.1) 0px -2px 10px;
        margin: 0!important;
        display: flex;
        column-gap: 10px;
        row-gap: 10px;
        flex-direction: column;
        > a.btn,
        > button {
            width: 100%!important;
            float: none;
            margin: 0!important;
            &.productview__cart {
                /* force no margin on product page */
                margin: 0!important;
            }
            &:first-child {
                margin-bottom: 0!important;
            }
            &:last-child {
                margin-bottom: 10px!important;
            }
        }
        .addToCartBtn { /* LP */
            font-size: 26px!important;
            line-height: normal!important;
            padding: 10px 0!important;
            margin: 0!important;
            span {
                font-size: 1.2em!important;
                line-height: 1em!important;
            }
        }
	}
}

@media screen and (max-width: 400px) {
    .stickyMobileBottomBar {
        .addToCartBtn { /* LP */
            font-size: 18px!important;
            line-height: normal!important;
            padding: 10px 0!important;
            margin: 0!important;
            span {
                font-size: 1.2em!important;
                line-height: 1em!important;
            }
        }
    }
}
