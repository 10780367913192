.emaerket {
	@media (max-width: 768px) {
		display: none;
	}
	max-width: 60px;
	max-height: 60px;
	svg {
		min-width: 60px!important;
		opacity: .5;
	}
}

.emaerket_header {
	float: right;
	margin: 0 5px;
}

.emaerket_product,
.emaerket_basket {
	margin-right: 30px;
}

.emaerket_basket_header {
	position: absolute;
    top: 0;
    left: 0;
}

.emaerket_lp {
	text-align: center!important;
	padding: 25px 0 0;
}

.emaerket_product_header_mobile {
	display: none;
}

@media screen and (max-width: 991px) {
	.emaerket_header {
		display: none;
	}
	.emaerket_product {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.emaerket_lp {
		padding: 10px 0;
	}
	.emaerket_product_header_mobile {
		text-align: center;
		padding: 15px 0 30px 0;
		display: block;
	}
}