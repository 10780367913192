@media screen and (max-width: $mediumDesktop) {
	.productview__gallery--overlay .product__title {
		font-size: 26px;
		margin: 30px 0;
	}
	.productview__gallery--overlay {
		.col-md-9 { padding: 0}
	}
	.productview__gallery--wrapper row1 { padding-right: 0 15px}
	.productview__gallery--overlay .gallery__image--thumbnail {max-height: 50px}
	.productview__gallery--overlay .gallery__specification .productview__specification { top: 12% }
	.productview__gallery--wrapper {padding-left: 10px}

	.chat { display: none}
}

@media all and (orientation:portrait) {
	.productview__gallery--overlay .gallery__specification { display: none }
	.productview__gallery--overlay .hero__image { width: 100%; padding: 0 15px }
	.productview__gallery--close {right: 15px}
	.productview__gallery--overlay .product__title { margin-top: 50px;}
}