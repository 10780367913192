// 560px

@media screen and (max-width: $phoneDevice) {
	.checkout__row>div:first-child,
	.checkout__row>div:last-child,
	.checkout__row>div {
		width: 100%;
	}
	.footer__module h3 + div,
	.footer__module h3 + ul {
		overflow: hidden;
		height: 0;
		margin-bottom: 15px;
		transition: height 0.15s ease-out;
	}
	.footer__module .active h3 + div,
	.footer__module .active h3 + ul {
		height: auto;
	}
	.footer__module .active h3 {
		&::after {
			content: "-";
		}
	}
	.footer__module h3 {
		border-bottom: 1px solid #5f626d;
		padding-bottom: 15px;
		position: relative;
		&:hover {
			cursor: pointer;
		}
		&::after {
			content: "+";
			display: block;
			width: 30px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 10px;
			color: #fff;
			font-size: 20px;
		}
	}
	.mypage__details { width: 100% }
	.mypage__promobox,
	.breadcrumb{ display: none }
	.checkout__row>div:last-child {
		padding-left: 0;
		border: 0
	}
	.checkout__basket--small,
	.checkout__row { padding-top: 10px}
	.checkout__row>div { padding-bottom: 15px; margin-bottom: 15px; border-bottom: 1px solid #ccc}
	.basket input[type=email], .basket input[type=password], .basket input[type=tel], .basket input[type=text], .basket input[type=url], .basket select, .basket textarea { width: 95%}
	.checkbox input[type=checkbox] { margin-left: 0}
	.handel__logo,
	.itemPrint,
	.article-info-term,
	.category-name { display: none!important }
	.hbox__inner .hbox__module,
	.hbox__inner .hbox__module img { width: 100%; padding: 0 }
	.hbox { padding: 0 10px 10px!important }
	.hbox__inner .hbox__module {
		&:first-child > div { padding-left: 5px!important; }
		&:last-child > div { padding-right: 5px!important; }
	}
	.main .container-fluid { padding: 0}
	body.isFrontpage .header__inner .header__row { min-height: 80px }
	body .header__inner .header__row { min-height: 45px; padding-top: 10px }

	.basket .promoproduct__price,
	.basket .productview__price {
		display: block;
		width: 100%;
	}
	.view-products {
        .category__products.row .product {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 100px;
		}
		.category__filters .pull-right {
			float: left!important;
			margin-bottom: 10px;
		}
	}
	.glogos,
	.productview__navigation,
	.productview__tabs,
	.productview__social,
	.rating { display: none }
	.productview .details { display: block }
    .actions__wrapper > * {
        flex: 0 0 100%;
        padding: 0;
    }
	.navigation--prev,
	.navigation--next { display:  inline-block; width: 30px}
	.product__header {
		display: flex;
		flex: 0 0 25px;
		align-items: center;
	}
	.description__prices .productview__cart {
		position: relative;
		width: 100%;
	}
	.product__heading {
		padding: 0 10px;
		width: calc(100% - 60px)
	}
	.productview {
		h1 {
			font-size: 18px;
			margin: 0px 0 10px;
	    	font-weight: bold;
	    }
	    h2 { font-size: 14px!important; }
	}

	.productview h1+h2 { margin: 0 0 10px; font-size: 18px}

	.actions__wrapper .productview__wrapper.col-md-6,
	.actions__wrapper .productview__actions.col-md-6 { display: table-row; padding: 0 }
	.productview .productview__actions h2 { margin: 10px 0 }
	.productview__actions div { margin: 5px 0}
	.productview__intro {
		padding-bottom: 10px;
		margin-bottom: 0!important;
	}
	.productview__price { font-size: 18px; color: #111; }
	.productview__price strong { font-size: 18px; font-weight: bold; color: $snMainColor}
	.productview__actions .productview__price,
	.productview__actions .productview__cart {
		display: block;
		margin-bottom: 10px!important;
		position: relative;
		left: auto;
		width: 100%;
	}

	.productview .tab-content { padding: 15px 10px }
	.productview__gallery { padding: 15px 15px 10px }
	.checkout__steps + a { display: none }
	body .basket__table td:nth-of-type(3),
	body .basket__table th:nth-of-type(3){ display: none }
	.basket__table .product__image { padding: 0 0 10px 0; width: 100% }
	#member-registration .control-group input[type=tel], #member-registration .control-group input[type=text], #member-registration .control-group input[type=password], #member-registration .control-group input[type=email],
	.basket input[type=email], .basket input[type=password], .basket input[type=tel], .basket input[type=text], .basket input[type=url], .basket select, .basket textarea {
		width: 100%;
	}
	.view-checkout header { padding: 0 0 10px }
	.checkout__row>div:last-child { padding-right: 0}
	.checkout__row>div { border: 0 }
	.basket__summary {width: 100%}
	nav.breadcrumb { display: none }
	.view-article main img { width: 100%; max-width: 100%; padding: 5px 0!important; margin: 0 !important; height: auto }
	.search-results .col-xs-4,
	.search-results .col-xs-8 {
		width: 100%;
		padding: 0;
	}
	.search-results .prouduct__image { width: 100% }
	body .search-results .product__title,
	body .search-results .product__description { padding: 0 }
	.description__prices { position: relatvie; display: block; overflow: hidden; padding-top: 10px;}

	.com_content figure,
	.com_content figure.pull-left,
	.com_content figure.pull-right,
	.com_content figure.pull-center {
		margin: 10px 0;
		width: 100%!important;
		max-width: 100%;
	}
	.newsletter {
		input[type=text],
		#s_cust_Nazwisko  { width: 100%; margin: 6px 0 0; float: none }
	}
	body .site-search {
		height: 70px;
		padding-top: 5px;
	}
	.copyrights__inner > p { width: 100%; text-align: center }
	.copyrights__inner { padding-bottom: 30px }
	.copyrights__inner br { display: inline; content: ', '; clear:none }
	.promoproduct__price,
	.promoproduct__cart {
		display: block;
		position: relative;
		text-align: center;
		left: auto;
		right: auto;
		margin-right: 0!important;
	}
	.category__products .product__description { margin-bottom: 0px; }
	.datails__accordion {
		background-color: #fff;
		color: #111;
		cursor: pointer;
		padding: 18px;
		width: 100%;
		text-align: left;
		border: none;
		outline: none;
		transition: 0.4s;
		font-size: 15px;
		font-weight: bold;
		border-top: 1px solid #ededed;
	}

	.datails__accordion.active, .datails__accordion:hover {
		background-color: #fff;
		color: #111;
	}

	.details__panel {
		padding: 0 18px;
		background-color: #fff;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
	}
	.details__wrapper {
		padding: 10px 0;
	}
	.datails__accordion:after {
		content: '\02795'; /* Unicode character for "plus" sign (+) */
		font-size: 13px;
		color: #777;
		float: right;
		margin-left: 5px;
	}

	.datails__accordion.active:after {
		content: "\2796"; /* Unicode character for "minus" sign (-) */
	}
    .cart__regulations {
        max-width: calc(100% - 110px);
    }
    #xcolumns_cart {
        padding: 0 15px;
    }
}

// 450px
@media screen and (max-width: 450px) {
	.nav-tabs>li>a { margin-right: 0 }
	.productview .nav-tabs>li {
		margin: 0 0 5px;
		padding: 0;
		width: 100%;
		text-align: center;
	}
	.productview .nav-tabs>li>a,
	.productview .nav-tabs>li.active>a { border-bottom: 1px solid #d3d3d3 }
	.productview .nav-tabs>li.active>a,
	.productview .nav-tabs>li.active>a:focus,
	.productview .nav-tabs>li.active>a:hover { border-bottom: 5px solid $snBlue }
	.productview .productview__tabs { margin-top: 5px }
	.productview__actions .productview__price,
	.productview__actions .productview__cart { width: 100%; max-width: 100% }
	#cart__form--bottom .productview__cart { margin-bottom: 10px}
	.blog--grid {
		.blog__info,
		.item .blog__introtext { display: none }
	}
}

// 400px
@media screen and (max-width: $extraSmallDevice) {
	.sitewrapper.sitewrapper--opened {
		-webkit-transform: translate(-81%, 0);
	    -moz-transform: translate(-81%, 0);
	    -ms-transform: translate(-81%, 0);
	    -o-transform: translate(-81%, 0);
	    transform: translate(-81%, 0);
	}
	.basket__table,
	.basket__table .basket__price, .basket__table .basket__quantity, .basket__table .basket__total { font-size: 15px }
	.basket__table tbody td, .basket__table th { padding: 8px }
	body td.basket__price { min-width: 50px }
	.view-article main img { width: 100% !important }
	.mastercard__logo, .visa__logo { display: none }
}
@media screen and (max-width: 560px) {
	.mostpopular .mostpopular__inner .promoproduct { width: 100%!important; }
	.mostpopular .mostpopular__inner .clearfix.visible-xs-block { display: block!important}
	.promoproduct__price,
	.promoproduct__cart {
		display: block;
		position: relative;
		text-align: center;
	}
}
