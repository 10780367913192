body.layout-blog,
body.view-category,
body.view-article {
    .breadcrumb {
        font-size: 14px;
    }
    #sidebar {
        h3 {
            color: $sntext;
            font: 500 20px/1.4 "Noto Serif",Arial,serif;
        }
        ul li {
            list-style: none;
            * {
                font-family: Open\ Sans,Arial,serif;
                font-size: 16px;
                color: $sntext;
            }
            a:hover {
                color: $snMainColor;
            }
            h5 {
                margin: 0;
            }
        }
    }
}

.blog {
    margin-bottom: 30px;
    .cat-children {
        display: none;
    }
    .blog__introtext,
    .readmore {
        font-family: Open\ Sans,Arial,serif;
    }
}