.productview__gallery--wrapper {
    width: 100%;
    height: 100%;
    padding: 0 50px;
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gallery__info {
    text-align: center;
    position: relative;
    padding: 5px 3px;
    margin-top: -40px;
    margin-bottom: 11px;
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;

    &.soldout {
        background: $snMainColor;
    }
}

.productview__gallery.black {
    background: rgba(56, 56, 56, 1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(56, 56, 56, 1) 0%, rgba(56, 56, 56, 1) 32%, rgba(17, 17, 17, 1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(56, 56, 56, 1)), color-stop(32%, rgba(56, 56, 56, 1)), color-stop(100%, rgba(17, 17, 17, 1)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(56, 56, 56, 1) 0%, rgba(56, 56, 56, 1) 32%, rgba(17, 17, 17, 1) 100%);
    background: -o-radial-gradient(center, ellipse cover, rgba(56, 56, 56, 1) 0%, rgba(56, 56, 56, 1) 32%, rgba(17, 17, 17, 1) 100%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(56, 56, 56, 1) 0%, rgba(56, 56, 56, 1) 32%, rgba(17, 17, 17, 1) 100%);
    background: radial-gradient(ellipse at center, rgba(56, 56, 56, 1) 0%, rgba(56, 56, 56, 1) 32%, rgba(17, 17, 17, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#383838', endColorstr='#111111', GradientType=1);
}

.gallery__image--thumbnail {
    border: 1px solid #676767;
    cursor: pointer;
    height: 40px;
    max-height: 60px;
    margin: 5px;
    padding: 3px;

    width: auto;

    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    @include transition();

    &:hover {
        border-color: $snYellow
    }
}

.gallery__image--thumbnail.active {
    border-color: $snYellow
}

.gallery__image--video::before {
    border: 1px solid #fff;
    color: #fff;
    content: "\f04b";
    display: inline-block;
    line-height: 29px !important;
    text-indent: 2px;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    height: 30px;
    left: 36%;
    position: absolute;
    top: 36%;
    width: 30px;
    text-rendering: auto;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    &:hover {
        background: #ddd
    }
}

.gallery__thumbnails {
    width: 100%;

    ul {
        margin: 10px 0 0;
        padding: 0;
        width: 100%;
        text-align: center;
    }

    li {
        display: inline-block;
        position: relative;
        *display: inline;
        *zoom: 1;
    }
}

.productview__gallery--overlay {
    background: #1f1f1f;
    cursor: default;
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: -1;
    pointer-events: none;

    .embed-responsive.embed-responsive-16by9 {
        padding-bottom: 42.25%;
    }

    .gallery__specification {
        height: 100%;
        width: 22%;
        top: 0;
        float: right;
        position: relative;
        background: rgba(31, 31, 31, 1);
        background: -moz-linear-gradient(top, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 51%, rgba(46, 46, 46, 1) 67%, rgba(63, 63, 63, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(31, 31, 31, 1)), color-stop(51%, rgba(31, 31, 31, 1)), color-stop(77%, rgba(46, 46, 46, 1)), color-stop(100%, rgba(63, 63, 63, 1)));
        background: -webkit-linear-gradient(top, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 51%, rgba(46, 46, 46, 1) 67%, rgba(63, 63, 63, 1) 100%);
        background: -o-linear-gradient(top, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 51%, rgba(46, 46, 46, 1) 67%, rgba(63, 63, 63, 1) 100%);
        background: -ms-linear-gradient(top, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 51%, rgba(46, 46, 46, 1) 67%, rgba(63, 63, 63, 1) 100%);
        background: linear-gradient(to bottom, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 51%, rgba(46, 46, 46, 1) 67%, rgba(63, 63, 63, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f1f1f', endColorstr='#3f3f3f', GradientType=0);

        .productview__specification {
            position: absolute;
            bottom: 20%;
            padding-bottom: 20px;
            width: 100%
        }
    }

    .gallery--video {
        display: none
    }

    .overlay__thumbnails {
        background: #3F3F3F;
        width: 100%;
        position: fixed;
        bottom: 0;
        height: 20vh;

        .gallery__thumbnails {
            width: 75%;
            float: left;
        }
    }

    .product__title {
        color: #939393;
        font: normal 300 32px/1.3 'Noto Serif', Arial, serif;
        margin: 30px 0 20px;
        text-align: center;
    }

    .productview__specification {
        border: 0;
        color: #fff;
    }

    .gallery {
        padding: 0 0 0 20px;
        background: rgba(31, 31, 31, 1);
        background: -moz-linear-gradient(top, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 31%, rgba(46, 46, 46, 1) 57%, rgba(63, 63, 63, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(31, 31, 31, 1)), color-stop(31%, rgba(31, 31, 31, 1)), color-stop(57%, rgba(46, 46, 46, 1)), color-stop(100%, rgba(63, 63, 63, 1)));
        background: -webkit-linear-gradient(top, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        background: -o-linear-gradient(top, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        background: -ms-linear-gradient(top, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        background: linear-gradient(to bottom, rgba(31, 31, 31, 1) 0%, rgba(31, 31, 31, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#ddd', GradientType=0);
        vertical-align: top
    }

    .productview__gallery--wrapper {
        position: relative;
        height: 80vh;

        .hero__image {
            .galleryLoader {
                display: inline-block;
                font-size: 25px;
                top: 50%;
                position: relative;
                left: 50%;
                margin: -14px 0 0 -14px;
                color: #939393;
            }
        }
    }

    .overlay__thumbnails .productview__gallery--wrapper {
        height: 100%
    }

    .product__description {
        background: #fff;
        bottom: 0;
        margin: 0;
        padding: 20px;
        position: absolute;
        width: 100%;
    }

    .action__box {
        background: #fff;
        height: 100%;
        padding: 20px;
        width: 22%;
        float: right;
    }

    .hero__image {
        width: auto;
        max-height: 80vh;
        height: 80vh;
        float: left;
        width: 73%;

        // img {
        //     background: #1f1f1f;
        //     max-height: 60vh;
        //     width: auto
        // }
    }

    .overlay__cart {
        display: block;
        float: right;
        position: relative;
    }

    .special__price {
        font-size: 20px;
        margin-bottom: 30px;
        color: $snMainColor;
    }

    .gallery__thumbnails ul {
        margin-top: 0;
    }

    .gallery__image--hero {
        max-width: 100%;
        padding: 0 0 20px;
        display: block;
        margin: 0 auto;
        max-height: calc(100% - 113px);
    }

    .gallery__image--thumbnail {
        height: 75px;
        width: auto;
    }

    .regular__price {
        font-size: 20px
    }

    .productview__gallery--overlay .row1 {
        overflow: scroll
    }
}

.productview__gallery--thumbs {
    background: #3f3f3f
}

.productview__gallery--overlay.black {
    background: #1f1f1f;
}

.productview__gallery--close {
    position: absolute;
    color: #fff;
    font-size: 18px;
    top: 0.5em;
    right: 2em;
    cursor: pointer;
    z-index: 1000;
    cursor: pointer i {
        border-color: darken(#fff, 20%) !important;
        color: darken(#fff, 20%) !important;
    }
    .close-label {
        transition: opacity .15s;
        &:hover {
            opacity: .6;
        }
    }

    i {
        border: 1px solid #fff;
        font-size: 27px;
        height: 45px;
        padding: 8px 11px;
        margin-left: 10px;
        width: 45px;

        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
    }
}

/* Gallery sufixes */
/* For image background */
.productview__gallery.image {
    border-color: #ccc;
    .gallery__image--thumbnail {
        border-color: #ccc
    }
}

.productview__gallery--overlay.image {
    background: #fff;
    .gallery__specification {
        background: #fff;
    }
    .productview__gallery--close {
        right: 3em;
        padding: 2px 4px;
        background: #fff;
    }

    .productview__specification,
    .product__title {
        color: $sntext
    }

    .productview__gallery--close {
        color: $sntext;
        i {
            border-color: $sntext
        }
    }

    .overlay__thumbnails {
        background: #dddb
    }

    .product__description {
        background: #fff;
        border: 0;
    }

    .gallery__image--thumbnail {
        background: #fff4;
        &.active {
            background: #fff8;
        }
    }
}
.productview__gallery {
    .imageWrapper {
        position: relative;
        &:hover {
            cursor: zoom-in;
        }
    }
}

/* White */
.productview__gallery.white {
    border-color: #ccc;

    .gallery__image--thumbnail {
        border-color: #ccc
    }
}

.productview__gallery--overlay.white {
    background: #fff;
    .gallery__specification {
        background: rgba(221, 221, 221, 1);
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(31, 31, 31, 1)), color-stop(31%, rgba(31, 31, 31, 1)), color-stop(57%, rgba(46, 46, 46, 1)), color-stop(100%, rgba(63, 63, 63, 1)));
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 31%, rgba(221, 221, 221, 1) 57%, rgba(221, 221, 221, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#ddd', GradientType=0);
    }

    .productview__specification,
    .product__title {
        color: $sntext
    }

    .productview__gallery--close {
        color: $sntext;

        i {
            border-color: $sntext
        }
    }

    .overlay__thumbnails {
        background: #ddd
    }

    .product__description {
        background: #fff;
        border: 1px solid #ddd;
        border-bottom: 0;
    }
}

.productview__gallery {
    .imageWrapper {
        position: relative;

        &:hover {
            cursor: zoom-in;
        }
    }
}

/* Claret */
.productview__gallery.claret {
    background: rgba(130, 1, 7, 1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(130, 1, 7, 1) 0%, rgba(130, 1, 7, 1) 32%, rgba(79, 0, 3, 1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(130, 1, 7, 1)), color-stop(32%, rgba(130, 1, 7, 1)), color-stop(100%, rgba(79, 0, 3, 1)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(130, 1, 7, 1) 0%, rgba(130, 1, 7, 1) 32%, rgba(79, 0, 3, 1) 100%);
    background: -o-radial-gradient(center, ellipse cover, rgba(130, 1, 7, 1) 0%, rgba(130, 1, 7, 1) 32%, rgba(79, 0, 3, 1) 100%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(130, 1, 7, 1) 0%, rgba(130, 1, 7, 1) 32%, rgba(79, 0, 3, 1) 100%);
    background: radial-gradient(ellipse at center, rgba(130, 1, 7, 1) 0%, rgba(130, 1, 7, 1) 32%, rgba(79, 0, 3, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#820107', endColorstr='#4f0003', GradientType=1);
}

.productview__gallery--overlay.claret {
    background: $claret;
    .gallery__specification {
        background: $claret;
        background: -moz-linear-gradient(top, $claret 0%, $claret 31%, #3e0009 57%, rgba(221, 221, 221, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, $claret), color-stop(31%, $claret), color-stop(57%, rgba(46, 46, 46, 1)), color-stop(100%, rgba(63, 63, 63, 1)));
        background: -webkit-linear-gradient(top, $claret 0%, $claret 31%, #3e0009 57%, #3e0009 100%);
        background: -o-linear-gradient(top, $claret 0%, $claret 31%, #3e0009 57%, #3e0009 100%);
        background: -ms-linear-gradient(top, $claret 0%, $claret 31%, #3e0009 57%, #3e0009 100%);
        background: linear-gradient(to bottom, $claret 0%, $claret 31%, #3e0009 57%, #3e0009 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$claret', endColorstr='#3e0009', GradientType=0);
    }

    .productview__specification,
    .product__title {
        color: #fff
    }

    .productview__gallery--close {
        color: #fff;

        i {
            border-color: #fff
        }
    }

    .product__description {
        background: #fff
    }

    .overlay__thumbnails {
        background: #3e0009
    }
}

/* Navy */
.productview__gallery.navy {
    background: rgba(45, 67, 107, 1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 1) 32%, rgba(26, 40, 66, 1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(45, 67, 107, 1)), color-stop(32%, rgba(45, 67, 107, 1)), color-stop(100%, rgba(26, 40, 66, 1)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 1) 32%, rgba(26, 40, 66, 1) 100%);
    background: -o-radial-gradient(center, ellipse cover, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 1) 32%, rgba(26, 40, 66, 1) 100%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 1) 32%, rgba(26, 40, 66, 1) 100%);
    background: radial-gradient(ellipse at center, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 1) 32%, rgba(26, 40, 66, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2d436b', endColorstr='#1a2842', GradientType=1);
}

.productview__gallery--overlay.navy {
    background: $navyBlue;
    .gallery__specification {
        background: $navyBlue;
        background: -moz-linear-gradient(top, $navyBlue 0%, $navyBlue 31%, #1A2843 57%, rgba(221, 221, 221, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, $navyBlue), color-stop(31%, $navyBlue), color-stop(57%, #1A2843), color-stop(100%, #1A2843));
        background: -webkit-linear-gradient(top, $navyBlue 0%, $navyBlue 31%, #1A2843 57%, #1A2843 100%);
        background: -o-linear-gradient(top, $navyBlue 0%, $navyBlue 31%, #1A2843 57%, #1A2843 100%);
        background: -ms-linear-gradient(top, $navyBlue 0%, $navyBlue 31%, #1A2843 57%, #1A2843 100%);
        background: linear-gradient(to bottom, $navyBlue 0%, $navyBlue 31%, #1A2843 57%, #1A2843 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$navyBlue', endColorstr='#1A2843', GradientType=0);
    }

    .productview__specification,
    .product__title {
        color: #fff
    }

    .productview__gallery--close {
        color: #fff;

        i {
            border-color: #fff
        }
    }

    .product__description {
        background: #fff
    }

    .overlay__thumbnails {
        background: #1A2843
    }
}

/* Navy */
.productview__gallery.joanna {
    background: rgba(45, 67, 107, 1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 0.85) 32%, rgba(26, 40, 66, 0.85) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(45, 67, 107, 1)), color-stop(32%, rgba(45, 67, 107, 0.85)), color-stop(100%, rgba(26, 40, 66, 0.85)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 0.85) 32%, rgba(26, 40, 66, 0.85) 100%);
    background: -o-radial-gradient(center, ellipse cover, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 0.85) 32%, rgba(26, 40, 66, 0.85) 100%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 0.85) 32%, rgba(26, 40, 66, 0.85) 100%);
    background: radial-gradient(ellipse at center, rgba(45, 67, 107, 1) 0%, rgba(45, 67, 107, 0.85) 32%, rgba(26, 40, 66, 0.85) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2d436b', endColorstr='#1a2842', GradientType=1);
}

.productview__gallery--overlay.joanna {
    background: #41597E;
    background: -moz-linear-gradient(top, #41597E 0%, #41597E 31%, #41597E 57%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #41597E), color-stop(100%, #fff));
    background: -webkit-linear-gradient(top, #41597E 0%, #fff 100%);
    background: -o-linear-gradient(top, #41597E 0%, #fff 100%);
    background: -ms-linear-gradient(top, #41597E 0%, #fff 100%);
    background: linear-gradient(to bottom, #41597E 0%, #fff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$blissfulJoanna', endColorstr='#fff', GradientType=0);
    .gallery__specification {
        background: $blissfulJoanna;
        background: -moz-linear-gradient(top, $blissfulJoanna 0%, $blissfulJoanna 31%, #1A2843 57%, rgba(221, 221, 221, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, $blissfulJoanna), color-stop(31%, $blissfulJoanna), color-stop(57%, #1A2843), color-stop(100%, #1A2843));
        background: -webkit-linear-gradient(top, $blissfulJoanna 0%, $blissfulJoanna 31%, #1A2843 57%, #1A2843 100%);
        background: -o-linear-gradient(top, $blissfulJoanna 0%, $blissfulJoanna 31%, #1A2843 57%, #1A2843 100%);
        background: -ms-linear-gradient(top, $blissfulJoanna 0%, $blissfulJoanna 31%, #1A2843 57%, #1A2843 100%);
        background: linear-gradient(to bottom, $blissfulJoanna 0%, $blissfulJoanna 31%, #1A2843 57%, #1A2843 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$blissfulJoanna', endColorstr='#1A2843', GradientType=0);
    }

    .productview__specification,
    .product__title {
        color: #fff
    }

    .productview__gallery--close {
        color: #fff;

        i {
            border-color: #fff
        }
    }

    .product__description {
        background: #fff
    }

    .overlay__thumbnails {
        background: #1A2843
    }
}


@media (min-width: 561px) and (max-width: 1140px) {
    .productview__gallery {
        &.singleImage {
            height: 25.92vw;

            .imageWrapper {
                height: 100%;

                img {
                    margin: 0 auto;
                    display: block;
                    max-height: 100% !important;
                    width: auto;
                }
            }
        }

        &.multipleImages {
            height: auto;

            .imageWrapper {
                height: 21.65vw;

                img {
                    margin: 0 auto;
                    display: block;
                    max-height: 100% !important;
                    width: auto;
                }
            }
        }
    }
}

@media (min-width: 1141px) {
    .productview__gallery {
        &.singleImage {
            height: 300px;

            .imageWrapper {
                height: 100%;

                img {
                    margin: 0 auto;
                    display: block;
                    max-height: 100% !important;
                    width: auto;
                }
            }
        }

        &.multipleImages {
            height: auto;

            .imageWrapper {
                height: 248px;

                img {
                    margin: 0 auto;
                    display: block;
                    max-height: 100% !important;
                    width: auto;
                }
            }
        }
    }
}

@media (max-width: 440px) {
    .gallery__image--thumbnail {
        width: 70px;
    }
}

@media (max-width: 560px) {
    .actions__wrapper {
        overflow: visible;

        .productview__wrapper {
            &.col-md-6 {
                width: 100vw;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                display: block !important;
            }
        }
    }

    .productview__gallery {
        &.singleImage {
            height: 55vw;

            .imageWrapper {
                height: 100%;

                img {
                    max-height: 100% !important;
                    margin: 0 auto;
                    display: block;
                }
            }
        }

        &.multipleImages {
            height: auto;

            .imageWrapper {
                height: 40.8vw;

                img {
                    max-height: 100% !important;
                    margin: 0 auto;
                    display: block;
                }
            }
        }

        .gallery__overlay {
            top: 100%;
            left: 100%;
            position: absolute;
            -webkit-transition: none;
            -moz-transition: none;
            -o-transition: none;
            transition: none;
            margin: -35px 0 0 -35px;
            width: auto;
            height: auto;

            .overlay__circle {
                top: 0;
                left: 0;
                transform: none;
                width: 25px;
                height: 25px;

                i {
                    font-size: 13px;
                    line-height: 25px;
                }
            }
        }

        &:hover {
            .gallery__overlay {
                top: 100%;
                left: 100%;
                position: absolute;
                -webkit-transition: none;
                -moz-transition: none;
                -o-transition: none;
                transition: none;
                margin: -35px 0 0 -35px;
                width: auto;
                height: auto;
            }
        }
    }
}
.favorite-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    padding: 5px 20px;
    font-size: 24px;
    text-decoration: none;
}
@media (max-width: 767px) {
    .favorite-icon {
        padding: 7px;
    }
}
.icon-background {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 7px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .mobile-box-shadow {
        box-shadow: 0 0 10px rgb(180, 180, 180);
        outline-offset: -10px;
    }
}
