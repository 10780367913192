$reccomPadding: 15px;

.go_to_product {
    padding: 15px;
    color: #FFF !important;
    background-color: #852b2b;
    &:hover {
        background-color: #373b48;
    }
}
body.fr-FR, body.nl-NL, body.nl-BE{
    .go_to_product {
        background: #38761d!important;
    }
}

.recommended {
    clear: both;
    margin-bottom: 30px;
    a {
        color: $sntext;
        display: block;
        font-size: 14px;
        line-height: 1.3em;
        text-align: center;
        &:hover {
            color: $snMainColor;
        }
    }
    h3 {
        font: italic 400 22px/1.5 "Noto Serif", Arial, serif;
    }
    .recommended__container {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-template-rows: auto;
        column-gap: 15px;
        row-gap: 30px;
    }
    .recommended__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        border: 1px solid #d4d4d4;
        min-height: 240px;
        padding: $reccomPadding;
    }
    .recommended__img {
        max-width: 100%;
        max-height: 102px;
        width: auto;
        height: auto;
    }
    .recommended__button {
        font-size: 15px;
        min-height: 35px;
        padding: 8px;
        text-align: center;
        width: 100%;
        height: 100%;
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    .recommended__price {
        &--wrapper {
            font-size: 17px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 0 0 $reccomPadding;
            white-space: nowrap;
        }
        &--crossed {
            font-size: 80%;
            margin-right: 5px;
        }
    }
    .recommended__overlay {
        padding: 0px;
        position: absolute;
        top: 0;
        left: 0;
        max-width: 35%;
    }
    .recommended__bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: -$reccomPadding;
        margin-left: -$reccomPadding;
        margin-right: -$reccomPadding;
    }

    @media (max-width: 560px), (min-width: 769px) and (max-width: 991px) {
        .recommended__bottom {
            display: flex;
            flex-direction: column;
        }
        .recommended__price--wrapper,
        .productview__cart {
            width: 100%;
        }
        .recommended__price--wrapper {
            align-items: center;
            margin: 5px 0;
            flex-direction: row;
        }

        .productview__cart {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        .recommended__container {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .recommended__item {
            min-height: 200px;
        }
    }
}
