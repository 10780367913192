// max 680px
//
//

@media screen and (max-width: 680px) {
	.magnify > .magnify-lens,
	.header__cart-mini {
		display: none !important;
	}
	body .site-search {
		display: none;
		width: 100%;
		clear: both;
		height: 70px;

        .search-query {
            width: 100% !important;
        }
		.site-search__button {
			position: absolute;
			right: 0;
			top: 0
		}
		form {
			width: 98%;
			margin: 0 1%;
			position: relative;
		}
	}
	.mobilesearch { display: block }
	.header__phone,
	.handel__logo { display: none!important }
	.magazine .magazine__article > .col-md-8 { width: 100%}
	.view-basket .handel__logo,
	.view-basket .header__phone,
	.view-checkout .handel__logo,
	.view-checkout .header__phone,
	.view-thankyou .handel__logo,
	.view-thankyou .header__phone {display: block !important}
	.basket .productview__cart { width: auto }
	body.view-basket { padding: 0 !important }
	.header__inner .header__row { min-height: 55px }
	.basket__table tbody td, .basket__table tfoot td { padding: 12px }
	.basket__table .basket__price, .basket__table .basket__quantity, .basket__table .basket__total { font-size: 17px }
	.layout-blog .cat-children > div > div { display: table !important }
	.contact .col-md-6 { width: 100%; padding: 0 0 5px 0}
	.contact-miscinfo { display: none }
	.contact .control-group,
	.contact .control-label,
	.contact .controls,
	.contact input,
	.contact textarea { width: 100% }

	.basket__table input[type=checkbox] + label {
		margin-left: 0px;
	}
	.autocomplete-suggestions {
    	width: 98%;
    	margin: 0 1%;
	}
	body.com_search .autocomplete-suggestions { margin: 0; }
    .variant__label {
        margin: 7px 0;
    }
}


@media screen and (max-width: $smallDevice) {
	.hidden-phone { display: none }
	.minfobar { display: block }
	.minfobar > div,
	.minfobar > p {
		display: inline-block;
		margin-left: 3px;
	}
	.minfobar .infobar__langswitch { float: right }
	body.view-basket,
	body.view-checkout { padding: 0 }
	body.view-basket .main,
	body.view-checkout .main { padding: 15px }
	.productview__gallery--overlay .gallery__specification { display: none }
	.productview__gallery--overlay .hero__image { width: 100%; padding: 0}
	.productview__gallery--close i {
		font-size: 18px;
		height: 30px;
		padding: 4px 7px;
		margin-left: 10px;
		width: 30px;
	}
	.view-product .main { padding-top: 0}
	.productview__gallery { padding: 10px 10px 7px }
	.productview__gallery--close {right: 15px}
	.productview__gallery--overlay .product__title { margin-top: 50px;}
	.mostpopular__inner {
		/*.promoproduct { width: 100% }*/
		.promoproduct__title { min-height: 30px }
	}
	.container-fluid.mostpopular { padding: 0}
	.mostpopular .mostpopular__inner .clearfix.visible-sm-block { display: block!important}
	.mostpopular .mostpopular__inner .clearfix.visible-xs-block { display: none!important}
	.mostpopular .mostpopular__inner {
		.promoproducts { border: 0}
		.promoproduct {
			border: 0;
			display: inline-block;
			float: left;
			width: 50%;
			padding: 10px;
		}
		.promoproducts__row {
			display: block;
			width: 100%;
		}
		.promoproduct__title { min-height: auto; height: auto; }
		.promoproduct__actions {
			margin: 0;
			top: auto;
			position: relative;
		}
	}

	.magazine__article--secondary:last-child { padding-right: 0;}

	#topBarMenu { display: none }

	.footer__inner { padding: 25px 20px 35px }
	.footer__module .moduletable+.moduletable { margin-top: 20px }
	.footer__module .moduletable.bordered { margin-top: 20px }
	fieldset div { display: inline-block; margin-right: 20px;}
	.products__filters { display: none; visibility: hidden }
	.products__filters-inner { padding: 15px 10px 25px; margin: 15px 0 }
	.category__products .prouduct__image { width: 100% }
	fieldset+fieldset { margin-top: 20px}
	legend {margin-bottom: 10px}

	.infobar,
	.header__phone,
	.menu__row,
	.header-menu,
	.handel__logo {display: none}

	.chat {display: none}
	.image__box { max-width: 100% }
	.view-categories {
		.col-md-6 {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.menu__item {
		display: inline-block;
		font: normal 600 13px/40px 'Open Sans',Arial,serif;
		height: 45px;
		line-height: 45px;
		padding: 0 12px;
	}
	.categories-list { padding-left: 0 }
	.newsletter__inner .custom div { width: 100% !important; margin-bottom: 15px }
	.basket__table .product__usp,
	.basket__top,
	.checkout__steps { display: none}
	.view-basket .header__phone,
	.view-checkout .header__phone,
	.view-basket .handel__logo,
	.view-checkout .handel__logo,
	.view-checkout .header__phone,
	.view-thankyou .handel__logo,
	.view-thankyou .header__phone { display: block }
	.checkout__row { clear: both; padding-top: 10px }
	.checkbox input[type=checkbox] { margin-left: 3px}
	.bottom .bottom__module { padding-left: 0; padding-right: 0;}
	.view-checkout .payment_icons { position: relative; margin-left: 10px; }
	.checkout__row>div:last-child { padding-left: 20px }
	.basket ul.dl-horizontal { padding: 20px 0 20px 0 }
	.checkout__basket--small { margin: 20px 0 0 0 }
	.checkout__logos .handel__logo { display: none }
	.view-basket header, .view-checkout header, .view-thankyou header {
		min-height: 45px;
		padding: 10px 0 0;
	}
	body .checkout__logos img { float: left; margin: 10px 0 0; display: block }
	body .checkout__logos div { margin-right: 10px }
	.header__inner .header__row { min-height: 45px }
	.productview__actions { padding-bottom: 0}
	.productview__intro { padding-bottom: 10px; width: 100%; display: block }
	.hide__sidebar { display: block; margin-bottom: 10px }
	#sidebar { width: 100%; padding: 0; display: none }
	#content { width: 100%; padding: 0 }
	.view-categories .categories-list { padding-left: 0 }
	input[type=checkbox], input[type=radio], input[type=radio]:checked {
		width: 15px;
		height: 15px!important;
		margin-top: 4px!important;
		margin-right: 10px!important;
		padding-right: 15px !important;
	}
	.basket__table .basket__price { min-width: 100px }
	.productview__gallery--overlay .overlay__thumbnails .gallery__thumbnails { width: 100% }
	.productview__gallery--overlay .action__box { display: none }
	.productview__gallery--wrapper { padding: 0 10px}
	.view-profile .account { width: 100%; margin-bottom: 15px }
	#promocode__form {
		width: 100%;
		margin-top: 20px;
	}
}
/* Custom checkbox */
@media screen and (max-width:560px) {
  .basket__displayNo  {
    display: none !important;
  }
  .basket__displayYes {
    display: table-cell !important;
    min-width: 60px !important;
  }
  .basket__quantity div {
    margin: 0px !important;
    max-width: 20px !important;
  }
  .product_remove, .fa-times {
    display: inline-block  !important;
  }
}
