// 1024px



//@media screen and (max-width: $tabletWidth) and (min-width: $smallDevice) {
@media screen and (max-width: $tabletWidth) {
	.productview__gallery--overlay .gallery__specification { display: none }
	.productview__gallery--overlay .hero__image { width: 100%; padding: 0 15px }

	.productview__gallery--close {right: 15px}
	.productview__gallery--overlay .product__title { margin-top: 50px;}
	footer { padding: 10px 0 }
	.footer__inner { padding: 30px 15px }
	.products__filters-inner { padding: 25px 20px 35px; }
	fieldset div { display: inline-block; margin-right: 20px;}
	fieldset+fieldset { margin-top: 20px}
	.chat {display: none}
	.topbar .menu__item { padding: 0 20px }
	.menu__row {display: none}
	.infobar__text {margin-left: 10px}
	.main,
	.isFrontpage .main { padding-left: 10px; padding-right: 10px }
	#sidebar { padding: 0 25px 0 10px}
	.checkout__row>div:last-child { width: 50% }
	#member-registration .control-group .control-label {width: 100%}
	.header__inner .header__row {
		width: 100%;
		min-height: 95px;
	}
	.search { padding-top: 2px; }
	nav.navigation,
	.header__inner .header__row:first-child,
	#topbar { display: none }
}

@media screen and (max-width: 900px){
	.newsletter {
		input[type=text] { width: 49.5%; float: left; margin-right: 4px; }
		#s_cust_Nazwisko { float: right; margin-right: 0;}
		input[type=email],
		input[type=submit] { width: 100%; margin-top: 6px; }
	}
}